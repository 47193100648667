import { lazy } from 'react';

export default [
  {
    path: '/',
    exact: true,
    data: 'pages.home',
    showInNav: true,
    navName: 'Episodes',
    component: lazy(() => import('../pages/Home/Home'))
  },
  {
    path: '/episodes/chicken-and-waffles',
    data: 'episodes.chickenAndWaffles',
    exact: true,
    component: lazy(() => import('../pages/Episodes/EpisodeDetail'))
  },
  {
    path: '/episodes/pork-chops',
    data: 'episodes.porkChops',
    exact: true,
    component: lazy(() => import('../pages/Episodes/EpisodeDetail'))
  },
  {
    path: '/episodes/ribeye-steak',
    data: 'episodes.ribeyeSteak',
    exact: true,
    component: lazy(() => import('../pages/Episodes/EpisodeDetail'))
  },
  {
    path: '/episodes/shrimp-and-grits',
    data: 'episodes.shrimpAndGrits',
    exact: true,
    component: lazy(() => import('../pages/Episodes/EpisodeDetail'))
  },
  {
    path: '/episodes/shrimp-and-grits',
    data: 'episodes.shrimpAndGrits',
    exact: true,
    component: lazy(() => import('../pages/Episodes/EpisodeDetail'))
  },
  {
    path: '/episodes/potato-gnocchi',
    data: 'episodes.potatoGnocchi',
    exact: true,
    component: lazy(() => import('../pages/Episodes/EpisodeDetail'))
  },
  {
    path: '/episodes/lemon-chicken',
    data: 'episodes.lemonChicken',
    exact: true,
    component: lazy(() => import('../pages/Episodes/EpisodeDetail'))
  },
  {
    path: '/episodes/gaeng-phet',
    data: 'episodes.gaengPhet',
    exact: true,
    component: lazy(() => import('../pages/Episodes/EpisodeDetail'))
  },
  {
    path: '/episodes/pork-belly',
    data: 'episodes.porkBelly',
    exact: true,
    component: lazy(() => import('../pages/Episodes/EpisodeDetail'))
  },
  {
    path: '/episodes/native-ragout',
    data: 'episodes.nativeRagout',
    exact: true,
    component: lazy(() => import('../pages/Episodes/EpisodeDetail'))
  },
  {
    path: '/recipes',
    data: 'pages.recipes',
    exact: true,
    showInNav: true,
    navName: 'Recipes',
    component: lazy(() => import('../pages/Recipes/Recipes'))
  },
  {
    path: '/recipes/chicken-and-waffles',
    data: 'recipes.chickenAndWaffles',
    exact: true,
    component: lazy(() => import('../pages/Recipes/RecipeDetail'))
  },
  {
    path: '/recipes/pork-chops',
    data: 'recipes.porkChops',
    exact: true,
    component: lazy(() => import('../pages/Recipes/RecipeDetail'))
  },
  {
    path: '/recipes/ribeye-steak',
    data: 'recipes.ribeyeSteak',
    exact: true,
    component: lazy(() => import('../pages/Recipes/RecipeDetail'))
  },
  {
    path: '/recipes/shrimp-and-grits',
    data: 'recipes.shrimpAndGrits',
    exact: true,
    component: lazy(() => import('../pages/Recipes/RecipeDetail'))
  },
  {
    path: '/recipes/potato-gnocchi',
    data: 'recipes.potatoGnocchi',
    exact: true,
    component: lazy(() => import('../pages/Recipes/RecipeDetail'))
  },
  {
    path: '/recipes/lemon-chicken',
    data: 'recipes.lemonChicken',
    exact: true,
    component: lazy(() => import('../pages/Recipes/RecipeDetail'))
  },
  {
    path: '/recipes/gaeng-phet',
    data: 'recipes.gaengPhet',
    exact: true,
    component: lazy(() => import('../pages/Recipes/RecipeDetail'))
  },
  {
    path: '/recipes/pork-belly',
    data: 'recipes.porkBelly',
    exact: true,
    component: lazy(() => import('../pages/Recipes/RecipeDetail'))
  },
  {
    path: '/recipes/native-ragout',
    data: 'recipes.nativeRagout',
    exact: true,
    component: lazy(() => import('../pages/Recipes/RecipeDetail'))
  },
  {
    path: '/meet-the-chefs',
    data: 'pages.meetTheChefs',
    exact: true,
    showInNav: true,
    navName: 'Meet the Chefs',
    component: lazy(() => import('../pages/MeetTheChefs/MeetTheChefs'))
  },
  {
    path: '/cooking-with-gas',
    data: 'pages.cookingWithGas',
    exact: true,
    showInNav: true,
    navName: 'Cooking with Gas',
    component: lazy(() => import('../pages/CookingWithGas/CookingWithGas'))
  },
  {
    path: '/styleguide',
    data: 'pages.styleguide',
    exact: true,
    component: lazy(() => import('../pages/Styleguide/Styleguide'))
  },
  {
    //404 needs to be at bottom or it will overwrite others
    path: null,
    data: 'pages.notFound',
    component: lazy(() => import('../pages/NotFound/NotFound'))
  }
];
