// @flow

// Vendors
import React, { type Element, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';

// Components
import { Loader } from '../../components';

// Data
import { getPageData } from '../../data';

// Assets
import logoImage from '../../assets/img/logos/good-vs-gourmet.png';

// Styles
import './page.scss';

// Types
type Props = {
  data: string,
  children: Element<any>,
  component: Function
};

const Page = ({
  data: model,
  component: PageComponent,
  children,
  ...props
}: Props) => {
  const [isScrolled, setScrolled] = useState(false);
  const [pageData, setPageData] = useState({});

  const hasPageData = pageData && Object.keys(pageData).length;
  const className = classnames('page', {
    [`page--${pageData.className}`]: pageData.className,
    [`page--is-scrolled`]: isScrolled,
    [`page--has-transparent-nav`]: pageData.hasTransparentNav,
    [`page--has-rebate-callout`]: pageData.hasRebateCallout
  });

  const handleScroll = () => setScrolled(window.pageYOffset > 0);

  useEffect(() => {
    // Get the page data
    const data = getPageData(model);
    if (data) setPageData(data);
  }, [model]);

  useEffect(() => {
    // Reset the the window scroll position
    window.scrollTo(0, 0);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (!PageComponent && !children) {
    console.error('Page: `component` prop or `children` must be provided.');
    return null;
  }

  const pageTitle = hasPageData
    ? `${pageData.metadata.title || pageData.title}${
        model !== 'pages.home' ? ' | Good vs. Gourmet' : ''
      }`
    : 'Good vs. Gourmet';

  const ogImage =
    hasPageData && pageData.hero ? pageData.hero.image : logoImage;

  return (
    <>
      {hasPageData && (
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageData.metadata.description} />
          <meta property="og:title" content={pageTitle} />
          <meta
            property="og:description"
            content={pageData.metadata.description}
          />
          <meta property="og:image" content={ogImage} />
          <meta property="og:type" content={pageData.metadata.type} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:site_name" content="Good vs. Gourmet" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={pageTitle} />
          <meta
            name="twitter:description"
            content={pageData.metadata.description}
          />
          <meta name="twitter:url" content={window.location.href} />
          <meta name="twitter:image" content={ogImage} />
          <body className={className} />
        </Helmet>
      )}

      <div className="page__main">
        {!hasPageData && <Loader />}

        {hasPageData && (
          <>
            {PageComponent ? (
              <PageComponent {...props} pageData={pageData} />
            ) : (
              React.cloneElement(children, { ...props, pageData })
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Page;
