// Assets
import heroImage from '../../assets/img/hero/cooking-with-gas-hero.jpg';
import rebateImage from '../../assets/img/oven-range-large.png';
import { ReactComponent as BenefitTemperatureControlIcon } from '../../assets/img/icons/benefit-temperature-control.svg';
import { ReactComponent as BenefitFasterCookingIcon } from '../../assets/img/icons/benefit-faster-cooking.svg';
import { ReactComponent as BenefitVisualHeatIndicatorIcon } from '../../assets/img/icons/benefit-visual-heat-indicator.svg';
import { ReactComponent as BenefitFasterCooldownIcon } from '../../assets/img/icons/benefit-faster-cooldown.svg';

export default {
  hasTransparentNav: false,
  hasRebateCallout: false,
  metadata: {
    title: 'Benefits of Cooking with Gas',
    description:
      'Professional chefs name better temperature control and faster cooking time as main benefits of cooking on a natural gas stove.',
    type: 'website'
  },
  title: 'Cooking With Gas',
  className: 'cooking-with-gas',
  hero: {
    image: heroImage,
    video: '',
    title:
      'When it comes to cooking, professional chefs and home cooks prefer natural gas.',
    cta: '',
    link: ''
  },
  benefits: {
    subtitle: 'Natural Gas Benefits',
    title: '',
    cards: [
      {
        icon: BenefitTemperatureControlIcon,
        title: 'Precise Temperature Control',
        content:
          'Whether you need to turn the heat up high to get your pasta boiling or way down low so your cream sauce can simmer, you’re in control with natural gas.'
      },
      {
        icon: BenefitFasterCookingIcon,
        title: 'Faster Cooking',
        content:
          'Natural gas reacts immediately when you change the temperature, allowing for more even and faster cooking.'
      },
      {
        icon: BenefitVisualHeatIndicatorIcon,
        title: 'Visual Heat Indicator',
        content: 'The iconic blue flame lets you know when the heat is on.'
      },
      {
        icon: BenefitFasterCooldownIcon,
        title: 'Faster Cooldown Time',
        content:
          'When you turn off the heat, a natural gas stove cools down quickly. Unlike electric cooktops, which hold onto heat longer after they’re turned off.'
      }
    ]
  },
  rebate: {
    title: 'Get a natural gas rebate.',
    image: rebateImage,
    content: [
      'Oklahoma Natural Gas offers valuable rebates when you install qualifying energy efficient natural gas water heaters, clothes dryers, ranges and furnaces in new or existing homes.',
      'Rebate programs are available on a first-come, first-served basis. Funding and guidelines for these programs may be changed or discontinued at any time without notice.'
    ],
    ctas: [
      {
        as: 'a',
        href: 'https://www.oklahomanaturalgas.com/save-money/rebates-and-incentives/residential-rebates',
        target: '_blank',
        children: 'Residential Rebates'
      },
      {
        as: 'a',
        href:
          'https://www.oklahomanaturalgas.com/save-money/rebates-and-incentives/commercial-rebates-incentives',
        target: '_blank',
        children: 'Commercial Rebates'
      }
    ]
  }
};
