// Assets
import heroImage from '../../assets/img/hero/pork-chops-hero.jpg';
import thumbnail from '../../assets/img/recipes/pork-chops-thumb.jpg';

export default {
  hasTransparentNav: false,
  hasRebateCallout: true,
  metadata: {
    title: 'Pork Chops Recipe',
    description:
      'Two pork chop recipes: Classic pork chop with home potato fries vs double pork chop with tomato sauce.',
    type: 'article'
  },
  title: 'Pork Chops',
  hero: {
    image: heroImage,
    video: '',
    title: '',
    cta: '',
    link: ''
  },
  url: '/recipes/pork-chops',
  episode: 'porkChops',
  chef: 'kurtFleischfresser',
  thumbnail,
  season: 1,
  tag: 'S1:E3',
  good: {
    stats: [
      {
        key: 'Servings',
        value: '2 to 4'
      }
    ],
    description: 'Seared Pork Chops with Country Potatoes',
    note: '',
    recipeParts: [
      {
        partName: '',
        ingredients: [
          '3 russet potatoes',
          '4 pork chops (approximately 6 to 8 ounces each)',
          'kosher salt',
          'freshly ground black pepper',
          '2 tablespoons olive oil',
          '1 small yellow onion, thinly sliced',
          'freshly chopped parsley'
        ],
        ingredientsNote: '',
        instructions: [
          'Heat oven to 350°F. Prick potatoes all over with a fork. Brush or drizzle potatoes with olive oil. Generously season with kosher salt and freshly ground pepper. Place potatoes directly on an oven rack and roast until very soft when squeezed and skin is crisp, about 60 to 75 minutes. Set aside to cool slightly.',
          'Pat the pork dry with paper towels. Season well with salt and pepper. Heat oil in a heavy skillet over a medium flame. Fry the chops in the olive oil until nice and brown and cooked through, about 2 to 3 minutes per side. Transfer chops to a plate to rest.',
          'Add onions to the pan. Season with salt and pepper and sauté until translucent, about 5 minutes. Add freshly chopped parsley and stir. While the onions cook, peel and break up the potatoes. Add the potatoes to the pan and cook, stirring occasionally, until golden brown.'
        ]
      }
    ],
    buildTheDish: [
      'Spoon the potatoes onto the base of a medium-size plate. Place the pork chop on top of the potatoes and serve.'
    ]
  },
  gourmet: {
    stats: [
      {
        key: 'Servings',
        value: '2 to 4'
      }
    ],
    description: 'Roasted Double Pork Chops with Garlic and Pine Nuts',
    note: '',
    recipeParts: [
      {
        partName: '',
        ingredients: [
          '2 double-bone loin pork roast (approximately 1 pound each)',
          'sea salt',
          'freshly ground black pepper',
          '1 tablespoon olive oil (or your favorite cooking oil)',
          '12 cloves of garlic (skin-on)',
          '12 small Thai chiles',
          '½ cup dry white wine',
          '1 28-ounce can crushed San Marzano tomatoes',
          '½ cup toasted pine nuts',
          'a few fresh rosemary or thyme sprigs'
        ],
        ingredientsNote: '',
        instructions: [
          'Heat oven to 350°F. Pat the pork dry with paper towels. Score the fatty side of the pork chops to allow the fat to render out and to let the seasoning in. Season the pork well with salt and pepper.',
          'Heat a touch of oil in a heavy, oven-proof skillet over high heat. Sear the chops on all sides until they’re just golden brown, then arrange with the fattiest (scored) side down. Add the garlic cloves and chiles around the chops. Place the pan in the oven.',
          'Check the pork after 10 minutes. Cook until the internal temperature reaches 165°F on an instant-read thermometer (or is firm when squeezed against the grain). Transfer the chops to a cooling rack to rest.',
          'Place the skillet back on a medium flame. Using tongs, remove the cooked chiles and garlic cloves from the pan and place in a bowl to the side. Then, carefully pour the excess fat from the skillet into a separate bowl.',
          'Add the white wine and deglaze the pan, stirring with a wooden spoon to loosen any browned bits. Reduce the mixture by half, then add the crushed tomato puree. Add a sprig of fresh rosemary. Season with salt and pepper to taste. Cook, stirring occasionally, until most of the liquid has evaporated.'
        ]
      }
    ],
    buildTheDish: [
      'Spoon the tomato mixture onto a plate and arrange the garlic and chiles around the edges. Slice the chops between the bones and place the pork chop on the plate. Garnish with toasted pine nuts and a sprig of fresh rosemary or thyme.'
    ]
  }
};
