// Assets
import thumbnail from '../../assets/img/recipes/lemon-chicken-thumb.jpg';
import { goodLemonChicken1, gourmetLemonChicken1 } from '../recipes/images';

export default {
  hasTransparentNav: false,
  hasRebateCallout: true,
  metadata: {
    title: 'Lemon Chicken by Chef Miranda Kaiser',
    description:
      'Chef Miranda Kaiser, former owner of Laffa in Tulsa, cooks up two tasty versions of Moroccan lemon chicken, which is delicious served good or gourmet.',
    type: 'video.episode'
  },
  active: true,
  title: 'Lemon Chicken',
  hero: {
    image: '',
    video: '',
    title: '',
    cta: '',
    link: ''
  },
  episode: 'S2:E2',
  episodeLong: 'Season Two Episode Two',
  className: 'episode-detail',
  thumbnail,
  content:
    "Chef Miranda's Moroccan lemon chicken is delicious served good or gourmet! If you love roasted eggplant and red pepper as much as Chef Miranda does, this recipe is for you. Learn how to cook this recipe two ways.",
  excerpt:
    'Chef Miranda Kaiser, former owner of Laffa in Tulsa, cooks up two tasty versions of Moroccan lemon chicken, which is delicious served good or gourmet.',
  videoIds: [
    {
      id: 'g5ni-ijfi5Q',
      title: 'Good Lemon Chicken',
      thumbnail: goodLemonChicken1
    },
    {
      id: 'chRVYzxTXmc',
      title: 'Gourmet Lemon Chicken',
      thumbnail: gourmetLemonChicken1
    }
  ],
  playlistId: '',
  url: '/episodes/lemon-chicken',
  recipe: 'lemonChicken',
  chef: 'mirandaKaiser',
  season: 2
};
