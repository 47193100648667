// Assets
import image from '../../assets/img/chefs/sebastian-renner.jpg';

export default {
  name: 'Sebastian Renner',
  firstName: 'Sebastian',
  role: 'Head Chef at Tacos x Mezcal',
  image,
  bio: 'Tacos x Mezcal brings traditional Mexican dishes that represent the country’s true street food experience. The owners partnered with Sebastian, a prominent and highly skilled chef from Punta de Mita, Nayarit, Mexico. He has developed the restaurant’s menu consisting of authentic Mexican street food with his creative touch and flavoring that represent the spirit of Mexican cuisine.',
  recipe: 'porkBelly',
  episode: 'porkBelly',
  season: 2
};
