// @flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Styles
import './icon.scss';

// Types
type Props = {
  name: string,
  addCircle?: boolean,
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
  className?: string
};

const Icon = ({
  name,
  size = 'md',
  addCircle = false,
  className,
  ...props
}: Props) => {
  const classNames = classnames(
    'icon',
    {
      [`wl-icon-${name}`]: name,
      [`icon--${size}`]: size,
      [`icon--round-border`]: addCircle
    },
    className
  );

  return <i className={classNames} {...props} />;
};

export default Icon;
