export default {
  hasTransparentNav: false,
  hasRebateCallout: true,
  metadata: {
    title: 'Styleguide',
    description: 'Good vs. Gourmet',
    type: 'website'
  },
  title: 'The Styleguide',
  className: 'styleguide',
  content: ''
};
