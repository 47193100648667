// Polyfills
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// Vendors
import React from 'react';
import { hydrate, render } from 'react-dom';
import TagManager from 'react-gtm-module';

// Service worker
import * as serviceWorker from './serviceWorker';

// Helpers
import { isEnv } from './util/env';

// App
import App from './App';

if (isEnv('production')) {
    TagManager.initialize({ gtmId: 'GTM-TKZHKG8' });
}

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) hydrate(<App />, rootElement);
else render(<App />, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
