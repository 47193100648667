// Assets
import heroImage from '../../assets/img/hero/potato-gnocchi-hero.jpg';
import thumbnail from '../../assets/img/recipes/potato-gnocchi-thumb.jpg';
import {
  goodPotatoGnocchi1,
  goodPotatoGnocchi2,
  goodPotatoGnocchi3,
  goodPotatoGnocchi4,
  goodPotatoGnocchi5,
  goodPotatoGnocchi6,
  goodPotatoGnocchi7,
  goodPotatoGnocchi8,
  gourmetPotatoGnocchi1,
  gourmetPotatoGnocchi2,
  gourmetPotatoGnocchi3,
  gourmetPotatoGnocchi4,
  gourmetPotatoGnocchi5,
  gourmetPotatoGnocchi6,
  gourmetPotatoGnocchi7,
  gourmetPotatoGnocchi8,
  gourmetPotatoGnocchi9,
  gourmetPotatoGnocchi10,
  gourmetPotatoGnocchi11,
  gourmetPotatoGnocchi12,
  gourmetPotatoGnocchi13,
  gourmetPotatoGnocchi14,
  gourmetPotatoGnocchi15
} from './images';

export default {
  hasTransparentNav: false,
  hasRebateCallout: true,
  metadata: {
    title: 'Potato Gnocchi Recipe',
    description:
      'Even after 35 years of cooking, Chef Kathryn Mathis agrees that, “You don’t have to be creative to cook.” Give her potato gnocchi a try and learn how to take the dish from good to gourmet on a natural gas range.',
    type: 'article'
  },
  title: 'Potato Gnocchi',
  hero: {
    image: heroImage,
    video: '',
    title: '',
    cta: '',
    link: ''
  },
  url: '/recipes/potato-gnocchi',
  episode: 'potatoGnocchi',
  chef: 'kathrynMathis',
  thumbnail,
  season: 2,
  tag: 'S2:E1',
  good: {
    stats: [
      {
        key: 'Servings',
        value: '4'
      }
    ],
    description:
      'Tender gnocchi pairs perfectly with mushrooms, Brussels sprouts, charred cherry tomatoes and basil pesto in this classic Italian dish.',
    note: '',
    image: goodPotatoGnocchi1,
    recipeParts: [
      {
        partName: '',
        ingredients: [
          '16oz (1 package) potato gnocchi',
          '1 cup basil pesto',
          '8 oz white mushrooms, sliced',
          '8 oz Brussels sprouts',
          '2 cups cherry tomatoes, halved',
          '2 garlic cloves, chopped',
          '2 tbsp white wine',
          '1 tbsp butter',
          '2 tbsp extra virgin olive oil',
          'kosher salt',
          'fresh-ground black pepper',
          'fresh parsley or basil',
          'Parmesan, for garnish'
        ],
        ingredientsNote: '',
        instructions: [
          {
            description:
              'Add a generous handful of salt to a large pot of water (it should be salty like the ocean). Bring to a boil.',
            images: [goodPotatoGnocchi2]
          },
          {
            description:
              'Meanwhile, heat oil in a large skillet over medium-high heat. When very hot, add the mushrooms and Brussels sprouts. Season with salt and pepper to taste, and cook, stirring often until the mushrooms and sprouts just begin to become tender, about 4 to 5 minutes.',
            images: [goodPotatoGnocchi3, goodPotatoGnocchi4]
          },
          {
            description:
              'Add the garlic, season with salt and pepper, and cook, stirring often, about 1 minute. Add the cherry tomatoes to the skillet and cook, stirring just until tomatoes are slightly charred and a few have burst, about 3 minutes. Season with salt and pepper.',
            images: []
          },
          {
            description:
              'Add the gnocchi to the boiling pot of water, cook for about 1-2 minutes. Be careful not to overcook; they should float to the top when done. Transfer the hot gnocchi to the skillet.',
            images: [goodPotatoGnocchi5, goodPotatoGnocchi6]
          },
          {
            description:
              'Deglaze the skillet. Add the white wine, and cook, stirring until about 1/2 the liquid has evaporated. Add the pesto to the skillet and stir; season with salt and pepper to taste. Add the butter to taste and adjust.',
            images: [goodPotatoGnocchi7]
          }
        ]
      }
    ],
    buildTheDish: [
      {
        description:
          'Serve immediately, garnishing with fresh basil or parsley and grated Parmesan, as desired.',
        images: [goodPotatoGnocchi8]
      }
    ]
  },
  gourmet: {
    stats: [
      {
        key: 'Servings',
        value: '4'
      }
    ],
    description:
      'In this elevated Italian dish, homemade gnocchi mingles with a trio of wild mushrooms, Brussels sprouts, charred cherry tomatoes and arugula walnut pesto.',
    note: '',
    image: gourmetPotatoGnocchi1,
    recipeParts: [
      {
        partName: 'Arugula Walnut Pesto',
        ingredients: [
          '2-3 cups arugula',
          '1/4 cup walnuts, roughly chopped',
          '2 cloves garlic',
          '½ cup grated Parmesan',
          '1 tsp salt',
          '1 tsp fresh-ground black pepper',
          '1 tsp lemon juice',
          '1 cup extra virgin olive oil, divided'
        ],
        ingredientsNote: '',
        instructions: [
          {
            description:
              'Using a food processor, purée the Parmesan, walnuts, garlic, lemon juice, 1/2 the extra virgin olive oil, and salt and pepper to almost a paste. Add the arugula, more olive oil, and purée. You might need to add a tablespoon or more of oil, depending on the texture you would like. Set puréed pesto aside.',
            images: [gourmetPotatoGnocchi2, gourmetPotatoGnocchi3]
          }
        ]
      },
      {
        partName: 'Handmade Potato Gnocchi',
        ingredients: [
          '1 lb russet potatoes, peeled and diced',
          '1 cup all-purpose flour, extra to dust',
          '1 egg at room temperature',
          '1 tsp salt, plus more for the water'
        ],
        ingredientsNote: '',
        instructions: [
          {
            description:
              'Add a generous handful of salt to a pot of water and bring to a boil. Add the potatoes and boil until a fork can easily pierce a potato, about 20 minutes. Drain the potatoes and set aside until cool enough to handle.',
            images: [gourmetPotatoGnocchi4]
          },
          {
            description:
              'Once the potatoes have cooled, pass them through a food mill or potato ricer into a large bowl.',
            images: [gourmetPotatoGnocchi5]
          },
          {
            description:
              'Add 1 teaspoon of salt to the processed potatoes and, using your hands, combine the mixture. Turn the potato mixture out of the bowl and onto a floured surface. Make a well in the center and add 1 cup of flour. Crack an egg into the well and stir with a fork until everything is evenly distributed.',
            images: [gourmetPotatoGnocchi6]
          },
          {
            description:
              'Working quickly and carefully, knead and fold the mixture into a ball of dough. Be careful not to overwork the dough, only incorporating as much flour as you need along the way until the dough loses stickiness and becomes more solid.',
            images: [gourmetPotatoGnocchi7]
          },
          {
            description:
              'Return the ball of dough to the bowl, cover with a kitchen towel and let rest for at least 20 minutes.',
            images: []
          },
          {
            description:
              'Slice the dough into 4 parts. Working in batches, take 1 section of dough, and using both hands, roll the dough into a rope, about ½-inch thick. Flour the dough as needed to keep it from getting sticky. Slice the rope into ½-inch squares and set aside on a lightly floured surface. Repeat with the remaining sections.',
            images: [
              gourmetPotatoGnocchi8,
              gourmetPotatoGnocchi9,
              gourmetPotatoGnocchi10
            ]
          },
          {
            description:
              'Holding a fork at a 45° angle on your work surface, slide each gnocchi square from the base of the fork prongs to the top so they make a decorative shape. As it wraps around the tip of your thumb, it will form into a dumpling. Or, using a wooden gnocchi board, roll the squares of dough down the board with your thumb to create a grooved surface. Repeat the process until the other 3 balls are done.',
            images: [gourmetPotatoGnocchi11, gourmetPotatoGnocchi12]
          },
          {
            description:
              'Bring a pot of salted water to a boil. Add the gnocchi in batches, stirring gently once or twice to ensure they are not sticking. Boil until the gnocchi floats to the surface, about 1 to 2 minutes. Remove and set onto a sheet pan; drizzle with olive oil.',
            images: []
          }
        ]
      },
      {
        partName: 'Wild Mushrooms',
        ingredients: [
          '8 oz mixed wild mushrooms (such as lion mane, trumpet and maitake), cleaned and sliced medium-thick',
          '2 cups Brussels sprouts, sliced into ¼ slices',
          '2 cloves of garlic, chopped',
          '1 tbsp butter',
          '½ cup extra virgin olive oil',
          '2 tbsp white wine',
          '1 tsp salt',
          '1 tsp fresh-ground black pepper',
          'Parmesan, for garnish'
        ],
        ingredientsNote: '',
        instructions: [
          {
            description: '',
            images: [gourmetPotatoGnocchi13]
          }
        ]
      },
      {
        partName: 'Roasted Cherry Tomatoes',
        ingredients: ['1 cup cherry tomatoes', '1 tbsp extra virgin olive oil'],
        ingredientsNote: '',
        instructions: [
          {
            description:
              'Heat a skillet over high heat until barely smoking. Add the olive oil and tomatoes and cook, until the tomatoes are lightly charred and about to burst, about 3 minutes. Remove tomatoes from heat and set aside.',
            images: [gourmetPotatoGnocchi14]
          },
          {
            description:
              'In a skillet over high heat, add the olive oil and butter. When very hot, add the garlic and cook until it just begins to turn golden, about 1 minute. Immediately add the mushrooms, season with salt and pepper and cook, stirring, until the mushrooms just begin to become tender, about 3 minutes.',
            images: []
          },
          {
            description:
              'Add the hot gnocchi mixture to the skillet. Deglaze with 2 tablespoons of white wine, and cook, stirring, until about 1/2 the liquid has evaporated. Add 2 to 3 ounces of the arugula walnut pesto to the skillet and stir until combined.',
            images: []
          }
        ]
      }
    ],
    buildTheDish: [
      {
        description:
          'Serve immediately, garnishing with fresh basil or parsley and grated Parmesan, as desired.',
        images: [gourmetPotatoGnocchi15]
      }
    ]
  }
};
