// Assets
import image from '../../assets/img/chefs/kathryn-mathis.jpg';

export default {
  name: 'Kathryn Mathis',
  firstName: 'Kathryn',
  role: 'Head Chef and Owner of Pizzeria Gusto',
  image,
  bio: 'Kathryn’s passion for Italian cooking shines through in every pasta dish and pizza slice served at Pizzeria Gusto. She is also the executive chef and co-owner of two other OKC community favorites: Big Truck Tacos and Back Door BBQ.',
  recipe: 'potatoGnocchi',
  episode: 'potatoGnocchi',
  season: 2
};
