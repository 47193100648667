// @flow

// Vendors
import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { Icon } from '../../components';

// Assets
import comingSoonImage from '../../assets/img/episodes/coming-soon.jpg';

// Styles
import './episode.scss';

type Props = {
  active: boolean,
  title: string,
  episode: string,
  excerpt: string,
  url: string,
  thumbnail: string,
  className: string
};

const Episode = ({
  active,
  title,
  episode,
  excerpt,
  url,
  thumbnail,
  className
}: Props) => {
  const T = active ? Link : 'div';
  const classNames = classnames(
    'episode',
    {
      [`episode--inactive`]: !active
    },
    className
  );

  return (
    <article className={classNames}>
      <T to={url} className={classnames({ [`episode__link`]: active })}>
        <div className="episode__media-container">
          <img
            src={active ? thumbnail : comingSoonImage}
            className="episode__media"
            alt=""
          />
          {active && (
            <Icon
              name="video-play-triangle-small"
              className="episode__play-icon"
            />
          )}
        </div>
        <section className="episode__body">
          <p className="episode__number">{episode}</p>
          <h3 className="episode__title">{title}</h3>
          <p className="episode__description u--font-weight-light">{excerpt}</p>
        </section>
      </T>
    </article>
  );
};

export default Episode;
