// Vendors
import React from 'react';
import classnames from 'classnames';

// Styles
import './callout.scss';

// Types
type Props = {
  text: string,
  link: string,
  image: string,
  color: string,
  className: string
};

const Callout = ({
  link,
  text,
  image,
  color = 'orange',
  className,
  children
}: Props) => {
  const classNames = classnames(
    'callout',
    {
      [`u--border-color-${color}`]: color,
      [`callout--with-img`]: image
    },
    className
  );

  return (
    <div className={classNames}>
      {image && <img src={image} alt={children} className="callout__img" />}
      <div className="callout__content">
        <div className="callout__text">{children}</div>
        <div className="callout__cta">
          <a
            href={link}
            className="callout__cta-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {text}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Callout;
