// Assets
import heroImage from '../../assets/img/hero/shrimp-and-grits-hero.jpg';
import thumbnail from '../../assets/img/recipes/shrimp-and-grits-thumb.jpg';

export default {
  hasTransparentNav: false,
  hasRebateCallout: true,
  metadata: {
    title: 'Shrimp and Grits',
    description:
      'Two versions of this traditional recipe: New Orleans’ classic shrimp and grits vs shrimp and grits on fried grit cakes.',
    type: 'article'
  },
  title: 'Shrimp and Grits',
  hero: {
    image: heroImage,
    video: '',
    title: '',
    cta: '',
    link: ''
  },
  url: '/recipes/shrimp-and-grits',
  episode: 'shrimpAndGrits',
  chef: 'tiffanyTisdaleBraxton',
  thumbnail,
  season: 1,
  tag: 'S1:E2',
  good: {
    stats: [
      {
        key: 'Servings',
        value: '2 to 4'
      }
    ],
    description:
      'Creamy Smoked Gouda Grits with Sautéed Shrimp, Peppers and Onion',
    note: '',
    recipeParts: [
      {
        partName: 'Grits',
        ingredients: [
          '2 cups chicken broth',
          '1 cup water',
          '1 cup grits',
          '¼ cup butter',
          '1½ cup grated smoked gouda cheese',
          '1 cup heavy cream',
          '½ teaspoon garlic powder',
          '1 teaspoon smoked paprika',
          '1 teaspoon Old Bay',
          '1 teaspoon salt',
          '½ teaspoon black pepper'
        ],
        ingredientsNote: '',
        instructions: []
      },
      {
        partName: 'Shrimp',
        ingredients: [
          '2 tablespoons butter',
          '⅓ cup chopped red bell pepper',
          '⅓ yellow chopped onion',
          '½ teaspoon chopped garlic',
          '12 ounces fresh shrimp, peeled and deveined with tails on, butterflied if desired',
          '1½ teaspoon red wine vinegar',
          '1 teaspoon Tabasco',
          '1 tablespoon lemon juice',
          '½ teaspoon garlic powder',
          '½ teaspoon smoked paprika',
          '½ teaspoon salt',
          'fresh micro cilantro, or chopped cilantro or parsley, for garnish'
        ],
        ingredientsNote: '',
        instructions: [
          'Bring chicken broth and water to a simmer in a medium saucepan over medium high heat.',
          'Once mixture has begun to boil, stir in grits and cover the saucepan, turning the heat down to medium low. Stir occasionally to prevent sticking.',
          'Allow grits to simmer until they soften, about 5 to 7 minutes. Stir in heavy cream, butter, garlic powder, smoked paprika, Old Bay, salt and pepper and smoked gouda cheese. Keep warm while making shrimp.',
          'For the shrimp, heat butter in a medium skillet over medium-high heat. Add bell pepper, onion and garlic to skillet. Cook until they start to soften, about 4 minutes.',
          'Add shrimp and cook until they are light pink and fully cooked, about 6 minutes.',
          'Add vinegar, Tabasco, lemon juice, garlic powder and paprika. Season with salt to desired taste and give it a nice stir.'
        ]
      }
    ],
    buildTheDish: [
      'Pour grits into a large pasta bowl. Top with the onion and bell pepper mix, followed by the shrimp. Garnish with fresh micro cilantro, chopped cilantro or parsley.'
    ]
  },
  gourmet: {
    stats: [
      {
        key: 'Servings',
        value: '2 to 4'
      }
    ],
    description:
      'Crispy Smoked Gouda Grit Cakes with Sautéed Butterflied Shrimp, Peppers and Onion and Tabasco Gastrique',
    note: '',
    recipeParts: [
      {
        partName: 'Crispy Smoked Gouda Grit Cakes',
        ingredients: [
          '2 cups chicken broth',
          '1 cup water',
          '1 cup grits',
          '¼ cup butter',
          '1½ cup grated smoked gouda cheese',
          '1 cup heavy cream',
          '½ teaspoon garlic powder',
          '1 teaspoon smoked paprika',
          '1 teaspoon Old Bay',
          '1 teaspoon salt',
          '½ teaspoon black pepper',
          '1 cup vegetable oil'
        ],
        ingredientsNote: 'Makes 8 to 12 cakes',
        instructions: []
      },
      {
        partName: 'Egg wash',
        ingredients: [
          '2 large eggs',
          '1 tablespoon water',
          '1 cup all-purpose flour'
        ],
        ingredientsNote: '',
        instructions: [
          'Bring chicken broth and water to a simmer in a medium saucepan over medium high heat.',
          'Once mixture has begun to boil, stir in grits and cover the saucepan, turning the heat down to medium low. Stir occasionally to prevent sticking.',
          'Allow grits to simmer until they soften, about 5 to 7 minutes. Stir in butter, cheese, heavy cream, garlic powder, paprika, Old Bay, and salt and pepper. Pour the grits from the pot on to a small rimmed baking tray. Set aside to cool to room temperature, then transfer tray with grits to the refrigerator and chill for 2 hours.',
          'Beat eggs and 1 tablespoon water in a shallow bowl. Place flour in a shallow bowl. Heat vegetable oil in skillet on medium high heat. Use glass drinking cup, cookie or biscuit cutter and cut out 4 to 8 small grit cakes.',
          'Dip grit cakes in egg wash and then in flour bowl, covering both sides. Fry grit cakes until light brown, about 5 to 7 minutes each side. Transfer grit cakes to a paper towel-lined plate to rest and to remove any excess oil. Keep warm in a 250°F oven until ready to serve.'
        ]
      },
      {
        partName: 'Sautéed Butterflied Shrimp, Peppers and Onion',
        ingredients: [
          '2 tablespoons butter',
          '⅓ cup chopped red bell pepper',
          '⅓ yellow chopped onion',
          '½ teaspoon chopped garlic',
          '12 ounces fresh shrimp, peeled and deveined with tails on, butterflied if desired',
          '1½ teaspoon red wine vinegar',
          '1 teaspoon Tabasco',
          '1 tablespoon lemon juice',
          '½ teaspoon garlic powder',
          '½ teaspoon smoked paprika',
          '½ teaspoon salt',
          'fresh micro cilantro, or chopped cilantro or parsley, for garnish'
        ],
        ingredientsNote: '',
        instructions: [
          'Heat butter in a medium skillet over medium-high heat. Add bell pepper, onion and garlic to skillet. Cook until they start to soften, about 4 minutes.',
          'Add shrimp and cook until shrimp are light pink and fully cooked, about 6 minutes.',
          'Add vinegar, Tabasco, lemon juice, garlic powder and smoked paprika. Season with salt and serve over grit cakes with a drizzle of gastrique. Garnish with micro greens and serve immediately.'
        ]
      },
      {
        partName: 'Tabasco Gastrique',
        ingredients: ['¼ cup Tabasco or other hot sauce', '¼ cup honey'],
        ingredientsNote: 'Makes ½ cup',
        instructions: ['Whisk together Tabasco and honey until combined.']
      }
    ],
    buildTheDish: [
      'Arrange 3 grit cakes onto a large dinner plate. Spoon a small amount of onion and bell pepper mix on top of each grit cake, followed by one shrimp. Drizzle the Tabasco gastrique across each cake (amount depends on desired spice level). Garnish with fresh micro cilantro, chopped cilantro or parsley.'
    ]
  }
};
