// @flow

// Vendors
import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

// Styles
import './button.scss';

// Types
type Props = {
  as?: 'a' | 'button',
  color?: string,
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
  className?: string
};

const Button = ({
  as = 'a',
  color = '',
  size = '',
  className,
  ...props
}: Props) => {
  const classNames = classnames(
    'btn',
    {
      [`btn--${size}`]: size,
      [`btn--${color}`]: color
    },
    className
  );

  const tags = {
    a: 'a',
    button: 'button',
    Link
  };
  const T = tags[as] || as;

  return <T className={classNames} {...props} />;
};

export default Button;
