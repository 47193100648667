import home from './home';
import cookingWithGas from './cookingWithGas';
import meetTheChefs from './meetTheChefs';
import recipes from './recipes';
import styleguide from './styleguide';
import notFound from './notFound';

const pages = {
  home,
  cookingWithGas,
  meetTheChefs,
  recipes,
  styleguide,
  notFound
};

export default pages;
