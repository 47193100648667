// Assets
import heroImage from '../../assets/img/hero/native-ragout-hero.jpg';
import thumbnail from '../../assets/img/recipes/native-ragout-thumb.jpg';
import {
  goodNativeRagout1,
  goodNativeRagout2,
  goodNativeRagout3,
  goodNativeRagout4,
  goodNativeRagout5,
  gourmetNativeRagout1,
  gourmetNativeRagout2,
  gourmetNativeRagout3,
  gourmetNativeRagout4,
  gourmetNativeRagout5
} from './images';

export default {
  hasTransparentNav: false,
  hasRebateCallout: true,
  metadata: {
    title: 'Native Ragout',
    description:
      '“In order to get to know someone really well, share a meal with them.” Chef Loretta walks us through two ways to create Native American ragout with special guest OKC Mayor David Holt. Add Chef Loretta’s good and gourmet version of Native American ragout to your recipe collection.',
    type: 'article'
  },
  title: 'Native Ragout',
  hero: {
    image: heroImage,
    video: '',
    title: '',
    cta: '',
    link: ''
  },
  url: '/recipes/native-ragout',
  episode: 'nativeRagout',
  chef: 'lorettaBarrettOden',
  thumbnail,
  season: 2,
  tag: 'S2:E5',
  good: {
    stats: [
      {
        key: 'Servings',
        value: '4-6'
      }
    ],
    description:
      'This easy, home version of braised beef ragout (or stew) is prepared with traditional indigenous ingredients.',
    note: '',
    image: goodNativeRagout1,
    recipeParts: [
      {
        partName: 'Roasted Beef',
        ingredients: [
          '3 lbs beef rump pot roast, sirloin tip or top round, cut into 1 1/2-inch cubes',
          '2 tbsp all-purpose flour',
          '2 tbsp olive oil ',
          '3 ounces pancetta or bacon, finely chopped',
          '1 yellow onion, finely chopped',
          '6 fresh flat-leaf parsley sprigs (or 1 tbsp dried)',
          '3 fresh thyme sprigs (or 2 tbsp dried)',
          '2 bay leaves',
          '4 garlic cloves, minced',
          '10-12 juniper berries, crushed',
          '1 1/2 C dry red wine (such as Barolo, Cabernet Sauvignon or Côtes du Rhône)',
          '4 cups chicken or beef broth',
          '1 tbsp tomato paste',
          '1 lb pearl onions',
          '2 tbsp unsalted butter',
          '1 lb small button mushrooms',
          '2 tbsp chopped fresh flat-leaf parsley (or dried)',
          'salt and fresh-ground pepper, to taste'
        ],
        ingredientsNote: '',
        instructions: []
      },
      {
        partName: 'Slow Cooker Directions',
        ingredients: [],
        ingredientsNote: '',
        instructions: [
          {
            description:
              'Add the remaining 1 tablespoon of olive to a skillet over medium-high heat. Working in batches, add the beef and cook, turning occasionally, until golden brown on all sides, about 10 to 15 minutes. Once the meat has browned, immediately add it to the slow cooker. Add the remaining ingredients and cover. Set the temperature to low for about 4-6 hours.',
            images: []
          }
        ]
      },
      {
        partName: 'Oven Braising Directions',
        ingredients: [],
        ingredientsNote: '',
        instructions: [
          {
            description:
              'Preheat the oven to 350°F. Place the venison or bison in a bowl or plastic bag, sprinkle with the flour and toss together to coat well. In a large Dutch oven over medium heat, warm 1 tablespoon of  olive oil. Add the pancetta and onion and saute until the onion is soft, about 10 minutes. Using a slotted spoon, transfer to a plate. Increase the heat to medium-high and add the remaining tablespoon of oil. Working in batches, add the bison/venison and cook, turning occasionally, until golden brown on all sides, 10 to 15 minutes.',
            images: [goodNativeRagout2]
          },
          {
            description:
              'If using fresh herbs, tie the parsley and thyme sprigs and bay leaves into a bundle with kitchen string. Otherwise sprinkle dried herbs into the slow cooker over the meat. Return the onion and pancetta to the pan along with the herb bundle and the garlic. Increase the heat to high and pour in the wine. Bring to a boil, stirring to scrape up any browned bits from the pan bottom, and boil until the wine is reduced by half, about 5 minutes. Add the broth and tomato paste and bring to a boil. Cover, place in the oven (or in a slow cooker for up to 6 hours) and cook until the bison/venison is very tender, about 1 1/2 hours.',
            images: [goodNativeRagout3]
          },
          {
            description:
              'Meanwhile, in a saucepan, combine the pearl onions with water to cover. Bring to a boil and boil for 2 minutes. Drain, rinse and drain again. Trim off the root end of each onion, then cut a shallow X in each trimmed end. Squeeze each onion gently to slip off the skin. Set aside. ',
            images: []
          },
          {
            description:
              'In a saucepan over medium-high heat, melt 1 tablespoon of butter. Add the mushrooms and cook, stirring occasionally, until lightly golden, about 10 minutes. Transfer to a plate. ',
            images: [goodNativeRagout4]
          },
          {
            description:
              'In the same pan, melt the remaining tablespoon of butter. Add the pearl onions and cook over medium heat, stirring occasionally, until lightly golden. Add onions and mushrooms to the slow cooker. When the venison/bison is tender, remove the herb bundle, season with salt and pepper, sprinkle with parsley and serve over buttery mashed potatoes.',
            images: []
          }
        ]
      }
    ],
    buildTheDish: [
      {
        description:
          'Divide between warm serving bowls, and serve with fresh skillet cornbread.',
        images: [goodNativeRagout5]
      }
    ]
  },
  gourmet: {
    stats: [
      {
        key: 'Servings',
        value: '6'
      }
    ],
    description:
      'Oven-braised bison ragout with juniper berries and other indeginous ingredients.',
    note: '',
    image: gourmetNativeRagout1,
    recipeParts: [
      {
        partName: '',
        ingredients: [
          '3 lbs boneless bison or venison such as rump pot roast, sirloin tip or top round, cut into 1 1/2-inch cubes',
          '2 tbsp all-purpose flour',
          '2 tbsp olive oil',
          '3 oz pancetta or bacon, finely chopped',
          '1 yellow onion, finely chopped ',
          '6 fresh flat-leaf parsley sprigs, plus 2 tbsp chopped  ',
          '3 fresh thyme sprigs',
          '2 bay leaves',
          '4 garlic cloves, minced ',
          '10-12 juniper berries, crushed',
          '1 1/2 cups dry red wine (such as Barolo, Cabernet Sauvignon or Côtes du Rhône) ',
          '4 cups chicken, beef or veal broth ',
          '1 tbsp tomato paste',
          '1 lb pearl onions',
          '2 tbsp unsalted butter',
          '1 lb small button mushrooms',
          'Salt and fresh-ground pepper, to taste',
          'Microgreens (optional), for garnish'
        ],
        ingredientsNote: '',
        instructions: [
          {
            description:
              'Preheat an oven to 350°F. Place the bison or venison in a bowl or plastic bag, sprinkle with the flour and toss together to coat well. ',
            images: [gourmetNativeRagout2]
          },
          {
            description:
              'In a large Dutch oven over medium heat, warm 1 tablespoon of the olive oil. Add the pancetta/bacon and onion and sauté until the onion is soft, about 10 minutes. Using a slotted spoon, transfer to a plate. ',
            images: []
          },
          {
            description:
              'Increase the heat to medium-high and add the remaining 1 tablespoon of oil. Working in batches, add the bison/venison and cook, turning occasionally, until golden brown on all sides, about 10 to 15 minutes.',
            images: []
          },
          {
            description:
              'Using kitchen string, tie the parsley and thyme sprigs and bay leaves into a bundle. Return the onion and pancetta/bacon to the pan along with the herb bundle and the garlic. Increase the heat to high and pour in the wine. Bring to a boil, stirring to scrape up any browned bits from the pan bottom, and boil until the wine is reduced by half, about 5 minutes. ',
            images: []
          },
          {
            description:
              'Add the broth and tomato paste and bring to a boil. Cover, place in the oven and cook until the venison/bison is fall-apart tender, about 1 ½ hours.',
            images: []
          },
          {
            description:
              'Meanwhile, in a saucepan, combine the pearl onions with water to cover. Bring to a boil and boil for 2 minutes. Drain, rinse and drain again. Trim off the root end of each onion, then cut a shallow X in each trimmed end. Squeeze each onion gently to slip off the skin. Set aside.',
            images: [gourmetNativeRagout3]
          },
          {
            description:
              'In a frying pan over medium-high heat, melt 1 tablespoon of the butter. Add the mushrooms and cook, stirring occasionally, until lightly golden, about 10 minutes. Transfer to a plate. Melt the remaining tablespoon of butter in the same pan. Add the pearl onions and cook over medium heat, stirring occasionally, until lightly golden, about 10 minutes. Set aside. When the bison/venison is tender, remove from the oven and add the onions.',
            images: [gourmetNativeRagout4]
          },
          {
            description:
              'Place over medium heat and bring to a simmer. Cook, uncovered, until the onions are tender, about 20 minutes. Add the mushrooms and stir to heat through. Discard the herb bundle. Stir in the chopped parsley and season with salt and pepper.',
            images: []
          }
        ]
      }
    ],
    buildTheDish: [
      {
        description:
          'Divide between warm serving bowls and garnish with fresh microgreens. If desired, serve over buttery polenta and serve with skillet cornbread.',
        images: [gourmetNativeRagout5]
      }
    ]
  }
};
