// Assets
import heroImage from '../../assets/img/hero/shrimp-and-grits-hero.jpg';
import thumbnail from '../../assets/img/recipes/shrimp-and-grits-thumb.jpg';

export default {
  hasTransparentNav: false,
  hasRebateCallout: true,
  metadata: {
    title: 'Shrimp and Grits by Chef Tiffany Tisdale-Braxton',
    description:
      'Chef Tiffany Tisdale-Braxton’s New Orleans-inspired recipe for creamy shrimp and grits sure hits the spot. And her crispy fried grit cakes are a pretty compelling take on the classic recipe.',
    type: 'video.episode'
  },
  active: true,
  title: 'Shrimp and Grits',
  hero: {
    image: heroImage,
    video: '',
    title: '',
    cta: '',
    link: ''
  },
  episode: 'S1:E2',
  episodeLong: 'Season One Episode Two',
  className: 'episode-detail',
  thumbnail,
  content:
    'Chef Tiffany Tisdale-Braxton’s New Orleans-inspired recipe for creamy shrimp and grits sure hits the spot. But her crispy fried grit cakes are a pretty compelling take on the classic recipe. Take your pick of grits, both enriched with plenty of smoked gouda and spices and, if you’re feeling fancy, give your dish an instant upgrade with her easy butterflied shrimp.',
  excerpt:
    'Tulsa native Chef Tiffany Tisdale-Braxton creates two styles of this famous Southern staple from scratch.',
  videoIds: [ { id: 'K7vg2w1XhN4' } ],
  playlistId: 'PL6bvPOjDXPzijS0wUPlVnGauAG99P2Ojn',
  url: '/episodes/shrimp-and-grits',
  recipe: 'shrimpAndGrits',
  chef: 'tiffanyTisdaleBraxton',
  season: 1
};
