// Assets
import heroImage from '../../assets/img/hero/home-hero-video-placeholder.jpg';

// Data
import { getSeason } from '../episodes';

export default {
  hasTransparentNav: true,
  hasRebateCallout: true,
  metadata: {
    title: 'Good vs. Gourmet Cooking Show | Oklahoma Natural Gas',
    description:
      'Professional chefs from Tulsa and Oklahoma City make their favorite dish, two ways, in Good vs. Gourmet cooking show. Which one would you make at home?',
    type: 'website'
  },
  title: 'Home Page',
  className: 'home',
  hero: {
    image: heroImage,
    video: `${process.env.PUBLIC_URL}/videos/home-hero.mp4`,
    title: 'Professional chefs cook one recipe two ways',
    cta: 'Watch Season 2 Episode 1',
    link: '/episodes/potato-gnocchi'
  },
  seasons: [
    { title: 'Season Two', episodes: getSeason(2) },
    { title: 'Season One', episodes: getSeason(1) }
  ],
  pageLinks: [
    {
      to: '/recipes',
      children: 'View Recipes'
    },
    {
      to: '/meet-the-chefs',
      children: 'Meet the Chefs'
    },
    {
      to: '/cooking-with-gas',
      children: 'Cooking with Gas'
    }
  ]
};
