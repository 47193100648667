// Assets
import heroImage from '../../assets/img/hero/chicken-and-waffles-hero.jpg';
import thumbnail from '../../assets/img/recipes/chicken-and-waffles-thumb.jpg';

export default {
  hasTransparentNav: false,
  hasRebateCallout: true,
  metadata: {
    title: 'Chicken and Waffles by Chef Melissa Aust',
    description:
      'Take your waffle-making skills up a notch with these two recipes from Chef Melissa Aust. Crispy fried chicken paired with traditional Belgian-style waffles topped with a spicy honey sauce or savory potato leek waffles smothered in smoked jalapeno gravy.',
    type: 'video.episode'
  },
  active: true,
  title: 'Chicken and Waffles',
  hero: {
    image: heroImage,
    video: '',
    title: '',
    cta: '',
    link: ''
  },
  episode: 'S1:E4',
  episodeLong: 'Season One Episode Four',
  className: 'episode-detail',
  thumbnail,
  content:
    'Take your waffle-making skills up a notch with these two recipes from Chef Melissa Aust. Crispy fried chicken is a no-brainer here, so there’s just two questions to ask yourself: Do you go for traditional Belgian-style waffles topped with a spicy honey sauce? Or savory potato leek waffles smothered in smoked jalapeño gravy? Hard to decide, but neither are hard to make.',
  excerpt:
    'It’s savory versus sweet in this brunch-worthy episode with Chef Melissa Aust from Oklahoma City.',
  videoIds: [ { id: '6_W0F6Z32Io' } ],
  playlistId: 'PL6bvPOjDXPzijS0wUPlVnGauAG99P2Ojn',
  url: '/episodes/chicken-and-waffles',
  recipe: 'chickenAndWaffles',
  chef: 'melissaAust',
  season: 1
};
