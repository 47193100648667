import ribeyeSteak from './ribeyeSteak';
import shrimpAndGrits from './shrimpAndGrits';
import porkChops from './porkChops';
import chickenAndWaffles from './chickenAndWaffles';
import potatoGnocchi from './potatoGnocchi';
import lemonChicken from './lemonChicken';
import gaengPhet from './gaengPhet';
import porkBelly from './porkBelly';
import nativeRagout from './nativeRagout';

const recipes = {
  ribeyeSteak,
  shrimpAndGrits,
  porkChops,
  chickenAndWaffles,
  potatoGnocchi,
  lemonChicken,
  gaengPhet,
  porkBelly,
  nativeRagout
};

export const getRecipes = () => Object.values(recipes);
export const getRecipe = (recipe) => recipes[recipe];
export const getSeason = (season) =>
  getRecipes().filter((v) => v.season === season);

export default recipes;
