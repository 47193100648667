// Assets
import heroImage from '../../assets/img/hero/ribeye-steak-hero.jpg';
import thumbnail from '../../assets/img/recipes/ribeye-steak-thumb.jpg';

export default {
  hasTransparentNav: false,
  hasRebateCallout: true,
  metadata: {
    title: 'Ribeye Steak by Chef Justin Thompson',
    description:
      'Chef Justin Thompson cooks two versions of this ribeye steak recipe - one gets a reverse-sear start in the oven and both steaks get seared in a skillet before being basted in a brown-butter bath.',
    type: 'video.episode'
  },
  active: true,
  title: 'Ribeye Steak',
  hero: {
    image: heroImage,
    video: '',
    title: '',
    cta: '',
    link: ''
  },
  episode: 'S1:E1',
  episodeLong: 'Season One Episode One',
  className: 'episode-detail',
  thumbnail,
  content:
    'Who needs a grill when you can recreate Chef Justin Thompson’s ribeye at home on the range? One ribeye gets a reverse-sear start in the oven (totally worth the time) and both steaks get seared in a skillet before being basted in a brown-butter bath. Elevate your reverse-seared steak even more with a delicious broccolini pesto and festive veggie ribbons.',
  excerpt:
    'To reverse sear or not to reverse sear? Tulsa’s own Chef Justin Thompson takes his favorite cut to new heights.',
  videoIds: [ { id: 'Y1TqOwbpbEM' } ],
  playlistId: 'PL6bvPOjDXPzijS0wUPlVnGauAG99P2Ojn',
  url: '/episodes/ribeye-steak',
  recipe: 'ribeyeSteak',
  chef: 'justinThompson',
  season: 1
};
