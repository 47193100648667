// @flow

// Vendors
import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

// Data
import { getEpisode } from '../../data/episodes';

// Components
import { Icon, Button, Modal } from '../../components';

// Styles
import './chef-card.scss';

// Types
import ChefType from '../../types';
type Props = ChefType;

const ChefCard = ({
  name,
  firstName,
  role,
  image,
  bio,
  cta,
  recipe,
  className,
  episode: episodeId,
  ...props
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [episode, setEpisode] = useState();
  const classNames = classnames('chef-card', className);

  useEffect(() => {
    if (episodeId) setEpisode(getEpisode(episodeId));
  }, [episodeId]);

  const handleModalToggle = (event) => {
    event.preventDefault();
    setShowModal(!showModal);
  };

  return (
    <article className={classNames} {...props}>
      <div className="chef-card__info">
        <img src={image} alt={name} className="chef-card__photo" />
        <div className="chef-card__details">
          <h3 className="chef-card__name">{name}</h3>
          <p className="chef-card__role">{role}</p>
        </div>
      </div>

      <p className="chef-card__bio">{bio}</p>

      {cta && (
        <>
          <div
            className="chef-card__cta"
            onClick={(event) => handleModalToggle(event)}
          >
            <Icon className="chef-card__cta-icon" name="video-clapboard" />
            <span className="chef-card__cta-text">{cta.text}</span>
          </div>
          <Modal
            show={showModal}
            handleClose={(event) => handleModalToggle(event)}
          >
            <div className="u--embed-responsive">
              <iframe
                src={`https://www.youtube.com/embed/${cta.videoId}?autoplay=1`}
                title={cta.text}
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
              />
            </div>
          </Modal>
        </>
      )}

      {episode && (
        <Button as="Link" to={episode.url} className="chef-card__recipe-btn">
          <Icon
            className="chef-card__recipe-btn-icon"
            name="video-play-triangle-small"
            size="lg"
          />
          <span>
            Watch {firstName} make {episode.title}
          </span>
        </Button>
      )}
    </article>
  );
};

export default ChefCard;
