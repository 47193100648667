// @flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import { Container } from '../../components';

// Styles
import './page-header.scss';

// Types
type Props = {
  title: string,
  className?: string
};

const PageHeader = ({ title, className, ...props }: Props) => {
  const classNames = classnames('page-header', className);

  return (
    <div className={classNames} {...props}>
      <Container size="sm">
        <h1 className="page-header__title">{title}</h1>
      </Container>
    </div>
  );
};

export default PageHeader;
