// Assets
import image from '../../assets/img/chefs/melissa-aust.jpg';

export default {
  name: 'Melissa Aust',
  firstName: 'Melissa',
  role: 'Executive Chef of Stella Modern Italian Cuisine',
  image,
  bio: 'California native Melissa Aust is the executive chef of Stella Modern Italian Cuisine. Her love for cooking began when she took over the family cooking duties as a teenager. Fast forward to 1990, when her culinary career took off. Graduating at the top of her class in culinary school, Melissa worked two seasons on Martha’s Vineyard. Shortly after, she took a detour to Oklahoma City to visit a friend and found her new home. At Stella, Melissa’s drive and passion brings a modern leap into the world of Italian cuisine. Because everything at the restaurant is special, she runs “additions” instead of “specials,” giving diners a variety of innovative dishes to experience, like a revamped favorite — chicken and corn risotto.',
  cta: {
    text: 'Chef Melissa reveals the inspiration behind the unique fare at Stella Modern Italian Cuisine.',
    videoId: 'G23fus8dtDI'
  },
  url: '/meet-the-chefs',
  recipe: 'chickenAndWaffles',
  episode: 'chickenAndWaffles',
  season: 1
};
