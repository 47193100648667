// Vendors
import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import classnames from 'classnames';
import { bodyScroll } from '../../util/bodyScroll';
import routes from '../../config/routes';

// Styles
import './nav.scss';

// Types
type Props = {
  className: string
};

const Nav = ({ className, ...props }: Props) => {
  const [mobileNavActive, setMobileNavActive] = useState(false);
  const navClassNames = classnames(
    'nav',
    {
      [`nav--mobile-active`]: mobileNavActive
    },
    className
  );

  useEffect(() => {
    if (mobileNavActive) bodyScroll.disable();
    else bodyScroll.enable();
  }, [mobileNavActive]);

  return (
    <nav className={navClassNames} {...props}>
      <div className={classnames('nav__container', className)}>
        <div className="nav__header">
          <Link to="/" className="nav__logo-link">
            <div className="nav__logo" />
          </Link>
          {/* desktop nav menu items */}
          <ul className="nav-links__container--desktop">
            {routes.map(({ showInNav, path, navName }) => {
              if (!showInNav) return null;
              return (
                <li key={navName}>
                  <NavLink to={path}>
                    <span>{navName}</span>
                  </NavLink>
                </li>
              );
            })}
          </ul>
          <button
            className={classnames('nav-hamburger', {
              [`nav-hamburger--active`]: mobileNavActive
            })}
            aria-label="Open Menu"
            onClick={() => setMobileNavActive(!mobileNavActive)}
          >
            <span className="nav-hamburger__icon"></span>
          </button>
        </div>
        {/* mobile nav menu items */}
        <ul className="nav-links__container--mobile">
          {routes.map(({ showInNav, path, navName }) => {
            if (!showInNav) return null;
            return (
              <li key={navName}>
                <NavLink
                  to={path}
                  onClick={() => setMobileNavActive(!mobileNavActive)}
                >
                  <span>{navName}</span>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
