// Assets
import image from '../../assets/img/chefs/loretta-barrett-oden.jpg';

export default {
  name: 'Loretta Barrett Oden',
  firstName: 'Loretta',
  role: 'Head Chef at Thirty Nine Restaurant',
  image,
  bio: "Loretta is an American chef, Native foods historian, food writer and television show host. She is an enrolled member of the Potawatomi tribe. She wrote and hosted the PBS series Seasoned With Spirit: A Native Cook's Journey. Oden writes a column, Spirit of the Harvest, for Native Peoples Magazine.",
  recipe: 'nativeRagout',
  episode: 'nativeRagout',
  season: 2
};
