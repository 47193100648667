// @flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Styles
import './container.scss';

// Types
type Props = {
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full',
  className: string
};

const Container = ({ size = 'lg', className, ...props }: Props) => {
  const classNames = classnames(
    'container',
    {
      [`container--${size}`]: size
    },
    className
  );

  return <div className={classNames} {...props} />;
};

export default Container;
