// Data
import { getSeason } from '../recipes';

export default {
  hasTransparentNav: false,
  hasRebateCallout: true,
  metadata: {
    title: "Chef's Recipes",
    description:
      'Each of four professional chefs from Oklahoma City and Tulsa makes two versions on the same recipe: one is good, one is gourmet.',
    type: 'website'
  },
  title: 'Recipes',
  className: 'recipes',
  hero: {
    image: '',
    title: 'Recipes'
  },
  content: '',
  seasons: [
    { title: 'Season Two', recipes: getSeason(2) },
    { title: 'Season One', recipes: getSeason(1) }
  ]
};
