// Assets
import image from '../../assets/img/chefs/justin-thompson.jpg';

export default {
  name: 'Justin Thompson',
  firstName: 'Justin',
  role: 'Proprietor of the Justin Thompson Restaurant Group',
  image,
  bio: 'Chef Justin Thompson is the proprietor of the Justin Thompson Restaurant Group, co-owner of Farrell Family Bakery and author of “Trial & Error: Recipes and Lessons Learned by a Chef & Restaurateur.” Since Justin began his culinary career in 2002, he’s created an empire of restaurants under his name: Juniper, PRHYME and 624 Catering and Events. Throughout his career, Justin has been named best chef in Tulsa by notable local magazines, received numerous awards and recognition from the Chaine des Rotisseurs, and served as guest chef at the James Beard House in New York City. The first dish he ever cooked? Chicken marsala.',
  cta: {
    text: 'Chef Justin talks about how he came to own restaurants in downtown Tulsa.',
    videoId: '2a-kwXU-TXk'
  },
  recipe: 'ribeyeSteak',
  episode: 'ribeyeSteak',
  season: 1
};
