// @flow

// Vendors
import React from 'react';

// Styles
import './loader.scss';

const Loader = () => (
  <div className="loading-container">
    <div className="loading loading--circle-bounce">
      {[...Array(3)].map((num, i) => (
        <div key={i} className="loading__ball" />
      ))}
    </div>
  </div>
);

export default Loader;
