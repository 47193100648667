import ribeyeSteak from './ribeyeSteak';
import shrimpAndGrits from './shrimpAndGrits';
import porkChops from './porkChops';
import chickenAndWaffles from './chickenAndWaffles';
import potatoGnocchi from './potatoGnocchi';
import gaengPhet from './gaengPhet';
import lemonChicken from './lemonChicken';
import nativeRagout from './nativeRagout';
import porkBelly from './porkBelly';

const episodes = {
  ribeyeSteak,
  shrimpAndGrits,
  porkChops,
  chickenAndWaffles,
  potatoGnocchi,
  lemonChicken,
  gaengPhet,
  porkBelly,
  nativeRagout
};

export const getEpisodes = () => Object.values(episodes);
export const getEpisode = (episode) => episodes[episode];
export const getSeason = (season) =>
  getEpisodes().filter((v) => v.season === season);

export default episodes;
