// Assets
import thumbnail from '../../assets/img/recipes/pork-belly-thumb.jpg';
import { goodPorkBelly1, gourmetPorkBelly1 } from '../recipes/images';

export default {
  hasTransparentNav: false,
  hasRebateCallout: true,
  metadata: {
    title: 'Pork Belly by Chef Sebastian Renner',
    description:
      'Sebastian Renner, head chef at Tacos x Mezcal, brings his Mexican pork belly recipes all the way from Guadalajara, Mexico. Learn two different takes on this dish.',
    type: 'video.episode'
  },
  active: true,
  title: 'Pork Belly',
  hero: {
    image: '',
    video: '',
    title: '',
    cta: '',
    link: ''
  },
  episode: 'S2:E4',
  episodeLong: 'Season Two Episode Four',
  className: 'episode-detail',
  thumbnail,
  content:
    '“The culture of eating and experiencing tacos is one of the most representative ways to eat any food in Mexico.” Chef Sebastian brings his recipe all the way from Guadalajara, Mexico. Learn two different takes on his Mexican pork belly.',
  excerpt:
    'Sebastian Renner, head chef at Tacos x Mezcal, brings his Mexican pork belly recipes all the way from Guadalajara, Mexico. Learn two different takes on this dish.',
  videoIds: [
    { id: '9qBarJUJJYc', title: 'Good Pork Belly', thumbnail: goodPorkBelly1 },
    {
      id: 'Zhon0owgdHY',
      title: 'Gourmet Pork Belly ',
      thumbnail: gourmetPorkBelly1
    }
  ],
  playlistId: '',
  url: '/episodes/pork-belly',
  recipe: 'porkBelly',
  chef: 'sebastianRenner',
  season: 2
};
