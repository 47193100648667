// Assets
import image from '../../assets/img/chefs/kurt-fleischfresser.jpg';

export default {
  name: 'Kurt Fleischfresser',
  firstName: 'Kurt',
  role: 'Co-founder of the Western Concepts Restaurant Group',
  image,
  bio: 'Kurt Fleischfresser, widely renowned chef and restaurateur, began his culinary career over 30 years ago, cooking at Oklahoma State University. He first trained under fine-dining French master Chef Bernard Cretier at Le Vichyssois in Chicago. Throughout his tenure, he’s helped transform the Oklahoma City food scene as the director of operations at Vast, owner of The Hutch and co-founder of the Western Concepts Restaurant Group. Kurt’s been twice invited to the James Beard House in New York City as part of the Great Regional Chefs Program and is a two-time finalist in the prestigious American Culinary Gold Cup Competition. In 2009, he was also awarded the Medaille de Merite by L’Academie de Gastronomie Brillat-Savarin for his contributions to culinary arts education. One of his most popular dishes is the rack of lamb.',
  cta: {
    text: 'Chef Kurt goes back to the beginning, when he first started running restaurants in Oklahoma City.',
    videoId: 'lVhE2a_UP3o'
  },
  recipe: 'porkChops',
  episode: 'porkChops',
  season: 1
};
