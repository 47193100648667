// Assets
import heroImage from '../../assets/img/hero/pork-belly-hero.jpg';
import thumbnail from '../../assets/img/recipes/pork-belly-thumb.jpg';
import {
  goodPorkBelly1,
  goodPorkBelly2,
  goodPorkBelly3,
  goodPorkBelly4,
  goodPorkBelly5,
  goodPorkBelly6,
  gourmetPorkBelly1,
  gourmetPorkBelly2,
  gourmetPorkBelly3,
  gourmetPorkBelly4,
  gourmetPorkBelly5,
  gourmetPorkBelly6,
  gourmetPorkBelly7,
  gourmetPorkBelly8
} from './images';

export default {
  hasTransparentNav: false,
  hasRebateCallout: true,
  metadata: {
    title: 'Pork Belly',
    description:
      '“The culture of eating and experiencing tacos is one of the most representative ways to eat any food in Mexico.” Chef Sebastian brings his recipe all the way from Guadalajara, Mexico. Learn two different takes on his Mexican pork belly.',
    type: 'article'
  },
  title: 'Pork Belly',
  hero: {
    image: heroImage,
    video: '',
    title: '',
    cta: '',
    link: ''
  },
  url: '/recipes/pork-belly',
  episode: 'porkBelly',
  chef: 'sebastianRenner',
  thumbnail,
  season: 2,
  tag: 'S2:E4',
  good: {
    stats: [
      {
        key: 'Servings',
        value: '4-6'
      }
    ],
    description:
      'Braised pork belly and cilantro wrapped in crispy corn tortillas, these authentic street food-style tacos represent the spirit of Mexican cuisine.',
    note: '',
    image: goodPorkBelly1,
    recipeParts: [
      {
        partName: '',
        ingredients: [
          '8 lbs pork fat (or lard)',
          '6 lbs pork belly (or pork cheek)',
          '4 bay leaves',
          '2 oranges, halved',
          '2 12 oz bottles dark beer',
          '⅔ tbsp whole black peppercorns',
          '1 ripe plantain',
          '2 ripe tomatoes, diced ',
          '½ small purple onion, diced ',
          '½ small bunch fresh cilantro, chiffonade ',
          '2 limes',
          '1 jalapeño or serrano pepper, diced',
          'salt',
          'corn tortillas'
        ],
        ingredientsNote: '',
        instructions: []
      },
      {
        partName: 'Pork Belly',
        ingredients: [],
        ingredientsNote: '',
        instructions: [
          {
            description:
              'Preheat the oven to 300°F. Place the pork belly in an oven-proof container or pot. Sprinkle black peppercorns over top, followed by the halved oranges and the beer. Pour the lard over the pork belly. Cover with aluminum foil and place on the center rack of the oven. Cook until the meat is tender and swimming in its own fat, between 2 1⁄2 to 3 hours.',
            images: [goodPorkBelly2, goodPorkBelly3]
          },
          {
            description:
              'Remove the container from the oven and transfer the pork belly to a tray. Let it rest until it cools or reaches room temperature. Once cool enough to handle, transfer a piece of pork belly to a cutting board and slice the pork into small pieces.',
            images: []
          }
        ]
      },
      {
        partName: 'Pico de Gallo',
        ingredients: [],
        ingredientsNote: '',
        instructions: [
          {
            description:
              'In a medium serving bowl, combine the chopped onion, jalapeño, juice of 2 limes and a pinch of salt. Let it marinate for about 5 minutes while you chop the tomatoes and cilantro. Add the chopped tomatoes and cilantro to the bowl and stir to combine. Taste, and add more salt if desired. For the best flavor, let the mixture marinate for 15 minutes before serving.',
            images: [goodPorkBelly4]
          }
        ]
      },
      {
        partName: 'Charred Tortillas',
        ingredients: [],
        ingredientsNote: '',
        instructions: [
          {
            description:
              'Turn your gas burner to medium, so that the flame just barely reaches the top of the grate.   Place a tortilla directly onto the burner, and heat for about 15 seconds, until the bottom is browned in spots. Then, using tongs, flip it over and toast the other side for about 15 seconds.',
            images: [goodPorkBelly5]
          }
        ]
      }
    ],
    buildTheDish: [
      {
        description:
          'Place the sliced pork belly on a charred tortilla and top with pico de gallo. Serve with a side of avocado, if desired.',
        images: [goodPorkBelly6]
      }
    ]
  },
  gourmet: {
    stats: [
      {
        key: 'Servings',
        value: '2-4'
      }
    ],
    description:
      'Inspired by authentic Mexican cuisine, crispy-edged, tender pork belly gets served over a creamy plantain purée with a tangy chimichurri topping.',
    note: '',
    image: gourmetPorkBelly1,
    recipeParts: [
      {
        partName: '',
        ingredients: [
          '8 lbs pork fat (or lard)',
          '6 lbs pork belly (or pork cheek) ',
          '4 bay leaves',
          '2 oranges, halved',
          '2 12 oz bottles dark beer',
          '⅔ tbsp whole black peppercorns',
          '2 ripe plantains',
          '1 cup butter',
          '1 tsp fresh cilantro, chiffonade ',
          '1 tsp purple onion, diced ',
          '2 tsp apple cider vinegar ',
          '2 tsp extra virgin olive oil',
          'salt',
          'wood chips (such as oak or cherry)'
        ],
        ingredientsNote: '',
        instructions: []
      },
      {
        partName: 'Pork Belly',
        ingredients: [],
        ingredientsNote: '',
        instructions: [
          {
            description:
              'Preheat the oven to 300°F. Place the pork belly in an oven-proof container or pot. Sprinkle black peppercorns over top, followed by the halved oranges and the beer. Pour the lard over the pork belly. Cover with aluminum foil and place on the center rack of the oven. Cook until the meat is tender and swimming in its own fat, between 2 1⁄2 to 3 hours.',
            images: [gourmetPorkBelly2]
          },
          {
            description:
              'Remove the container from the oven and transfer the pork belly to a tray. Let it rest until it cools or reaches room temperature. Once cool enough to handle, slice a piece of pork cheek for frying, about a 7 to 8 ounce strip.',
            images: [gourmetPorkBelly3]
          },
          {
            description:
              'Heat a cast iron skillet on the stove over medium heat. When hot, place the pork belly strip skin-side down and cook until the skin is crispy and golden brown, about 5 minutes.',
            images: [gourmetPorkBelly4]
          }
        ]
      },
      {
        partName: 'Smoked Plantain Purée',
        ingredients: [],
        ingredientsNote: '',
        instructions: [
          {
            description:
              'Preheat the oven to 300°F. Wrap the plantains with the skin-on in aluminum foil. Place them in the oven for 30 minutes.',
            images: [gourmetPorkBelly5]
          },
          {
            description:
              'Remove the skin from the plantains and place them to one side of a large, oven-safe container. Add the wood chips to the other side. Cover the container with aluminum foil, tightly seal, and smoke the plantains for 30 minutes. ',
            images: []
          },
          {
            description:
              'Remove the skin from the smoked plantains. Place them in a food processor with the butter and salt. Blend until you get a nice and smooth texture.',
            images: [gourmetPorkBelly6]
          }
        ]
      },
      {
        partName: 'Chimichurri Cilantro',
        ingredients: [],
        ingredientsNote: '',
        instructions: [
          {
            description:
              'Brunoise the purple onion, chiffonade the cilantro. Add the cilantro and onion in a small bowl, add the vinegar, extra virgin olive oil and salt to taste.',
            images: [gourmetPorkBelly7]
          }
        ]
      }
    ],
    buildTheDish: [
      {
        description:
          'Place the hot purée plantain over a plate. Slice the pork cheek and place it over the purée. Add about two full spoons of the chimichurri over the pork cheek.',
        images: [gourmetPorkBelly8]
      }
    ]
  }
};
