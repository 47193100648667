// Assets
import thumbnail from '../../assets/img/recipes/gaeng-phet-thumb.jpg';
import { goodGaengPhet1, gourmetGaengPhet1 } from '../recipes/images';

export default {
  hasTransparentNav: false,
  hasRebateCallout: true,
  metadata: {
    title: 'Gaeng Phet by Chef Jeff Chanchaleune',
    description:
      'Chef Jeff Chanchaleune, owner of Ma Der Lao Kitchen, shows us how to make Laotian Gaeng Phet. Your dinner guests will be asking for seconds when you recreate this recipe.',
    type: 'video.episode'
  },
  active: true,
  title: 'Gaeng Phet',
  hero: {
    image: '',
    video: '',
    title: '',
    cta: '',
    link: ''
  },
  episode: 'S2:E3',
  episodeLong: 'Season Two Episode Three',
  className: 'episode-detail',
  thumbnail,
  content:
    '“I grew up watching my mom create this recipe and I hope I made her proud.” Your dinner guests will be asking for seconds when you recreate Chef Jeff’s Laotian Gaeng Phet. Follow the recipe to take your dinner party to the next level.',
  excerpt:
    'Chef Jeff Chanchaleune, owner of Ma Der Lao Kitchen, shows us how to make Laotian Gaeng Phet. Your dinner guests will be asking for seconds when you recreate this recipe.',
  videoIds: [
    { id: 'FhdI2r_MQW0', title: 'Good Gaeng Phet', thumbnail: goodGaengPhet1 },
    {
      id: 'pQO-vNepTBI',
      title: 'Gourmet Gaeng Phet ',
      thumbnail: gourmetGaengPhet1
    }
  ],
  playlistId: '',
  url: '/episodes/gaeng-phet',
  recipe: 'gaengPhet',
  chef: 'jeffChanchaleune',
  season: 2
};
