// Assets
import heroImage from '../../assets/img/hero/gaeng-phet-hero.jpg';
import thumbnail from '../../assets/img/recipes/gaeng-phet-thumb.jpg';
import {
  goodGaengPhet1,
  goodGaengPhet2,
  goodGaengPhet3,
  goodGaengPhet4,
  gourmetGaengPhet1,
  gourmetGaengPhet2,
  gourmetGaengPhet3,
  gourmetGaengPhet4,
  gourmetGaengPhet5
} from './images';

export default {
  hasTransparentNav: false,
  hasRebateCallout: true,
  metadata: {
    title: 'Gaeng Phet',
    description:
      '“I grew up watching my mom create this recipe and I hope I made her proud.” Your dinner guests will be asking for seconds when you recreate Chef Jeff’s Laotian Gaeng Phet. Follow the recipe to take your dinner party to the next level.',
    type: 'article'
  },
  title: 'Gaeng Phet',
  hero: {
    image: heroImage,
    video: '',
    title: '',
    cta: '',
    link: ''
  },
  url: '/recipes/gaeng-phet',
  episode: 'gaengPhet',
  chef: 'jeffChanchaleune',
  thumbnail,
  season: 2,
  tag: 'S2:E3',
  good: {
    stats: [
      {
        key: 'Servings',
        value: '2-4'
      }
    ],
    description:
      'Red curry chicken gets served over steamed jasmine rice and sautéed yu choy in this comforting Laos dish.',
    note: '',
    image: goodGaengPhet1,
    recipeParts: [
      {
        partName: '',
        ingredients: [
          '2 lbs chicken wings, rinsed and patted dry',
          '1/4 cup canola oil',
          '½ cup shallots, julienned',
          '½ cup lemongrass, sliced',
          '1 tbsp galangal, sliced',
          '5 Kaffir lime leaves',
          '1 tbsp garlic, minced',
          '3 tbsp red curry paste',
          '1 can (13.5oz) coconut milk',
          '1 cup chicken stock',
          '1 tsp kosher salt',
          '2 tbsp fish sauce',
          '1/8 tsp white pepper, ground',
          '2 tbsp, plus 1 tsp granulated sugar',
          '1 cup Thai basil leaves, packed',
          '1 cup (about 3 large) Thai eggplant, sliced thick'
        ],
        ingredientsNote: '',
        instructions: [
          {
            description:
              'Using a heavy meat cleaver, cut the chicken wings in half. About 1-inch pieces. In a shallow pot, heat oil over medium heat. Add chicken pieces and cook until browned all over.',
            images: [goodGaengPhet2, goodGaengPhet3]
          },
          {
            description:
              'Add the shallots, lemongrass, galangal, Kaffir lime leaves, and garlic. Cook and sweat until fragrant, about 3 minutes. Add the red curry paste. Stir and continue to cook for another 2 minutes. Add the chicken stock, coconut milk, fish sauce, Thai basil, white pepper, sugar, and salt. Stir and bring to a simmer. Cover with lid and cook for 8-10 minutes until chicken starts to become tender. Add the eggplant and cook for 3-5 minutes longer until the eggplant is softened. Adjust seasoning with salt if needed.',
            images: []
          }
        ]
      }
    ],
    buildTheDish: [
      {
        description:
          'In a shallow rimmed plate, place the rice in the center. Scoop 2-3 spoons of the red curry chicken and sauce on top. Add more broth as desired. Garnish with fresh cilantro.',
        images: [goodGaengPhet4]
      }
    ]
  },
  gourmet: {
    stats: [
      {
        key: 'Servings',
        value: '4-6'
      }
    ],
    description:
      'Braised and fried bone-in chicken thighs in red curry sauce served with jasmine rice cooked in chicken stock with aromatics, sautéed yu choy and herbs.',
    note: '',
    image: gourmetGaengPhet1,
    recipeParts: [
      {
        partName: 'Chicken and Red Curry Sauce',
        ingredients: [
          '4-6 pieces bone-in, skin-on chicken thighs, rinsed and patted dry',
          '¼ cup canola oil, plus more for frying',
          'salt and pepper',
          '1 can (13.5oz) coconut milk',
          '1 cup chicken stock',
          '½ cup shallots, julienned',
          '½ cup lemongrass, sliced',
          '1 tbsp galangal, minced',
          '5 Kaffir lime leaves',
          '1 tbsp garlic, minced',
          '3 tbsp red curry paste',
          '1 tsp kosher salt',
          '2 tbsp fish sauce',
          '1/8 tsp white pepper, ground',
          '2 tbsp, plus 1 tsp granulated sugar',
          '1 cup Thai basil leaves, packed-fresh'
        ],
        ingredientsNote: '',
        instructions: [
          {
            description: 'Preheat the oven to 400°F.',
            images: []
          },
          {
            description: 'Season the chicken with salt and pepper.',
            images: []
          },
          {
            description:
              'Heat a Dutch oven with oil over medium-high heat. Place the chicken skin-side down in a saucepan to render some fat and tighten up the skin, about 2 minutes each side. Turn off heat. Remove chicken to a plate and reserve. Bring oil to 350°F. Fry the chicken thigh for 4-5 minutes until the internal temperature has reached 165°F and the skin is crispy.',
            images: [gourmetGaengPhet2]
          },
          {
            description:
              'Using the same oil, bring Dutch oven back to temp over medium heat. Add the shallots, lemongrass, galangal, kaffir lime leaves, and garlic. Cook and sweat until soft. Add the red curry paste and cook for 1-2 minutes more. Add the chicken stock, coconut milk salt, fish sauce, sugar, white pepper and basil. Stir. Bring mixture to a boil. Turn off heat. Add chicken thighs. Cover the Dutch oven and place in the center of the oven. Cook for 60-90 minutes until the chicken has reached 165°F and is tender.',
            images: []
          },
          {
            description:
              'Remove chicken from Dutch oven and let cool to room temperature. Strain braising liquid into a saucepan for the next step.',
            images: [gourmetGaengPhet3]
          },
          {
            description:
              'Bring the braising liquid to a simmer over low-medium heat. Cook and reduce until slightly thickened, about 5-10 minutes. Adjust seasoning if needed.',
            images: []
          }
        ]
      },
      {
        partName: 'Jasmine Rice',
        ingredients: [
          '2 cup jasmine rice, rinsed',
          '2 ½ cup chicken stock',
          '1 tbsp garlic, minced',
          '1 tsp ginger, minced',
          '2 tsp kosher salt',
          '½ cup cilantro, chopped'
        ],
        ingredientsNote: '',
        instructions: [
          {
            description:
              'Add all the ingredients except for cilantro to a medium saucepan. Stir, bring to a boil and turn down to simmer. Cook covered for 15-20 minutes until soft and water has evaporated. Fold in the cilantro.',
            images: []
          }
        ]
      },
      {
        partName: 'Sautéed Yu Choy',
        ingredients: ['1 lb yu choy', '1 tsp lime juice', 'salt and pepper'],
        ingredientsNote: '',
        instructions: [
          {
            description:
              'Heat a skillet over medium-high heat until barely smoking. Season the yu choy with salt, pepper, and oil. Sautée the yu choy and cook until tender. Toss with lime juice.',
            images: [gourmetGaengPhet4]
          }
        ]
      }
    ],
    buildTheDish: [
      {
        description:
          'In a shallow rimmed plate, place the rice in the center. Place one chicken thigh on top of the rice. Spoon 2-3 spoons of the sauce around the rice to create a small pool. Drape the yu choy on top of the sauce, around the chicken and rice. Garnish with fresh cilantro and basil leaves.',
        images: [gourmetGaengPhet5]
      }
    ]
  }
};
