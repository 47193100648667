// Assets
import image from '../../assets/img/chefs/jeff-chanchaleune.jpg';

export default {
  name: 'Jeff Chanchaleune',
  firstName: 'Jeff',
  role: 'Head Chef and Owner of Ma Der Lao Kitchen',
  image,
  bio: 'Jeff is known for offering and introducing Japanese-inspired dishes that haven’t been seen in Oklahoma City. He gained national attention as Eater’s “22 Hottest Food Trucks Across the US” and “14 Hottest Ramen Shops Across the US.” Jeff was a 2020 James Beard Foundation semifinalist for Best Chef in the Southwest region. He is also the owner and operator of two Japanese restaurants – a ramen shop (Goro Ramen) and a Laotian shop (Ma Der Lao Kitchen).',
  recipe: 'gaengPhet',
  episode: 'gaengPhet',
  season: 2
};
