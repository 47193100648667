// @flow

// Vendors
import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { Icon } from '../../components';

// Styles
import './hero.scss';

// Types
type Props = {
  showModal?: Function,
  image: string,
  video?: string,
  cta?: string,
  link?: string,
  title?: string,
  contentSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full',
  modalLink?: boolean,
  className?: string
};

const Hero = ({
  showModal,
  image,
  video,
  cta,
  link,
  title,
  contentSize = 'md',
  modalLink = false,
  className
}: Props) => {
  const classNames = classnames(
    `hero`,
    {
      [`hero--default`]: image,
      [`hero--default--overlay`]: link || cta || modalLink,
      [`hero--has-video`]: video
    },
    className
  );
  const heroLink = modalLink ? '#' : link;

  return (
    <div className={classNames} style={{ backgroundImage: `url(${image})` }}>
      {video && (
        <video className="hero__background-video" loop autoPlay muted>
          <source src={video} type="video/mp4" />
          <source src={video} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      )}
      <div
        className={classnames('hero__content', {
          [`hero__content--${contentSize}`]: contentSize
        })}
      >
        {title && <h1 className="hero__title">{title}</h1>}
        {heroLink && (
          <div className="hero__cta">
            <Link to={heroLink} onClick={showModal && showModal}>
              <Icon
                className="hero__icon wl-icon-video-play-triangle-small"
                size="xl"
              />
              {cta && <h6 className="hero__subtitle">{cta}</h6>}
            </Link>
          </div>
        )}
        {!heroLink && cta && <h3 className="hero__subtitle">{cta}</h3>}
      </div>
    </div>
  );
};

export default Hero;
