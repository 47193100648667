// Assets
import heroImage from '../../assets/img/hero/meet-the-chefs-hero.jpg';
import { getSeason } from '../chefs';

export default {
  hasTransparentNav: false,
  hasRebateCallout: true,
  metadata: {
    title: 'Meet the Chefs',
    description:
      'Meet four professional chefs from Oklahoma City and Tulsa who make two versions on the same recipe: one is good, one is gourmet.',
    type: 'website'
  },
  title: 'Meet The Chefs',
  className: 'meet-the-chefs',
  hero: {
    image: heroImage,
    title: 'Meet The Chefs'
  },
  seasons: [
    { title: 'Season Two Chefs', chefs: getSeason(2) },
    { title: 'Season One Chefs', chefs: getSeason(1) }
  ]
};
