// Assets
import heroImage from '../../assets/img/hero/lemon-chicken-hero.jpg';
import thumbnail from '../../assets/img/recipes/lemon-chicken-thumb.jpg';
import {
  goodLemonChicken1,
  goodLemonChicken2,
  goodLemonChicken3,
  goodLemonChicken4,
  goodLemonChicken5,
  goodLemonChicken6,
  goodLemonChicken7,
  gourmetLemonChicken1,
  gourmetLemonChicken2,
  gourmetLemonChicken3,
  gourmetLemonChicken4,
  gourmetLemonChicken5,
  gourmetLemonChicken6,
  gourmetLemonChicken7,
  gourmetLemonChicken8,
  gourmetLemonChicken9
} from './images';

export default {
  hasTransparentNav: false,
  hasRebateCallout: true,
  metadata: {
    title: 'Lemon Chicken Recipe',
    description:
      "Chef Miranda's Moroccan lemon chicken is delicious served good or gourmet! If you love roasted eggplant and red pepper as much as Chef Miranda does, this recipe is for you. Learn how to cook this recipe two ways.",
    type: 'article'
  },
  title: 'Lemon Chicken',
  hero: {
    image: heroImage,
    video: '',
    title: '',
    cta: '',
    link: ''
  },
  url: '/recipes/lemon-chicken',
  episode: 'lemonChicken',
  chef: 'mirandaKaiser',
  thumbnail,
  season: 2,
  tag: 'S2:E2',
  good: {
    stats: [
      {
        key: 'Servings',
        value: '2-4'
      }
    ],
    description:
      'One-pan Moroccan lemon chicken thighs made with a creamy roasted red pepper and eggplant sauce. Served with a side of roasted cherry tomatoes seasoned in a warm Ethiopian spice blend.',
    note: 'To assemble this dish, you will first need to marinate the chicken. (Note: marinade can be frozen or refrigerated for up to 8 days.)',
    image: goodLemonChicken1,
    recipeParts: [
      {
        partName: 'Moroccan Chicken Marinade',
        ingredients: [
          '4 boneless chicken thighs, trimmed',
          '1 container frozen lemonade concentrate (thawed)',
          '6 tbsp pollo bouillon',
          '4 tbsp fresh garlic, chopped',
          '1 cup fresh mint leaves',
          '1/2 bunch fresh cilantro',
          '14 scallions, roughly chopped',
          '2 tbsp cumin',
          '1 tsp coriander',
          '1/2 tsp turmeric',
          '1/2 tsp curry',
          '1/2 tsp allspice',
          '1 tsp cinnamon',
          '1 tsp smoked paprika',
          '1 tsp cajun seasoning'
        ],
        ingredientsNote: '',
        instructions: [
          {
            description:
              'Add the lemonade concentrate, pollo bouillon, garlic, mint, cilantro, and scallions to a food processor. Blend on high until well combined. Add the remaining seasonings to the food processor; blend well again.',
            images: []
          },
          {
            description:
              'Place chicken in a gallon-size resealable bag. Pour 1/4 cup of the marinade over the chicken and seal the bag while pressing out excess air. Refrigerate for at least 4 hours or overnight.',
            images: []
          }
        ]
      },
      {
        partName: 'Creamy Red Pepper Sauce',
        ingredients: [
          '4 chicken thighs marinated in lemon Moroccan marinade',
          '2-3 tbsp olive-canola oil',
          '1 lemon, sliced into rounds',
          '1 medium-large eggplant',
          '1 roasted red pepper',
          '3 heaping tbsp sun-dried tomato pesto',
          '1/4 cup white wine',
          '1 cup chicken stock made from bouillon',
          '1/4 cup heavy cream',
          '1 cup julienned spinach',
          'salt and pepper to taste',
          'chopped parsley, for garnish'
        ],
        ingredientsNote: '',
        instructions: [
          {
            description:
              'Add a teaspoon of the olive-canola oil blend to a skillet over medium heat. Add the lemon slices and cook, turning occasionally, for about 1 minute or until lemon slices are lightly browned. Transfer the charred lemon slices to a plate for later use.',
            images: [goodLemonChicken2]
          },
          {
            description:
              'Turn the burner on a gas range to medium and using tongs, place the eggplant directly on the open flame. Roast the eggplant for about 15-20 minutes, turning every 5 minutes or so. Be very careful not to burn yourself. You will know when it is fully roasted when the skin is completely soft and flaky and charred on the outside. Repeat this process with the red pepper. Note: You can roast the eggplant and red pepper on separate burners at the same time.',
            images: [goodLemonChicken3]
          },
          {
            description:
              'Transfer the roasted eggplant and red pepper to a plate and allow to cool, about 10-15 minutes. Once cool enough to handle, gently remove the black skin, making sure to keep the flesh intact. If some burnt bits of skin are left behind that is okay. Discard the rest of the skin.',
            images: []
          },
          {
            description:
              'Add ½ the charred eggplant to a food processor; reserve the other half. Add the charred red pepper and sun-dried tomato pesto to the food processor. Blend on high until well combined.',
            images: [goodLemonChicken4]
          },
          {
            description:
              'Add a tablespoon of the olive-canola oil blend to a skillet over medium-high heat. Add the marinated chicken thighs and brown, about 2-3 minutes each side. Set the browned chicken thighs on a plate to rest; don’t wipe out the skillet unless it is really burnt.',
            images: [goodLemonChicken5]
          },
          {
            description:
              'Add 1/4 cup white wine to the skillet and deglaze, scraping the brown bits on the bottom. Stir in the chicken stock and the blended red pepper-eggplant mixture and cook, stirring often, about 2-3 minutes. Add the heavy cream, the browned thighs and any leftover juices from the plate. Cook until the thighs reach 165°F and the sauce is cooked down to as thick as you want it.',
            images: []
          },
          {
            description:
              'Stir in the spinach, 1/2 the parsley and the charred lemon rounds and cook, stirring gently, a few minutes more. Season with salt and pepper to taste; loosen the sauce with water if necessary. Gently stir in the roasted tomatoes and sprinkle fresh parsley on top.',
            images: []
          }
        ]
      },
      {
        partName: 'Berbere Tomatoes',
        ingredients: [
          '1 pint of cherry tomatoes',
          '3/4 tsp berbere spice',
          '1 tsp kosher salt',
          '4 tbsp olive-canola oil blend'
        ],
        ingredientsNote: '',
        instructions: [
          {
            description:
              'Preheat the oven to 425°F. In a medium bowl, toss the tomatoes, berbere spice, salt and olive-canola oil blend together. Pour the tomatoes onto a parchment-lined sheet pan and roast on the top oven rack for 6 minutes.',
            images: [goodLemonChicken6]
          }
        ]
      }
    ],
    buildTheDish: [
      {
        description:
          'Place the chicken thighs on a large dinner plate. Spoon some of the creamy roasted red pepper sauce on top. Serve with roasted tomatoes and fresh green beans.',
        images: [goodLemonChicken7]
      }
    ]
  },
  gourmet: {
    stats: [
      {
        key: 'Servings',
        value: '2-4'
      }
    ],
    description:
      'Chicken roulade stuffed with a sweet-and-spicy golden raisin and roasted red pepper filling. Served with Moroccan-inspired cauliflower and charred tomatoes over creamed spinach.',
    note: '',
    image: gourmetLemonChicken1,
    recipeParts: [
      {
        partName: 'Moroccan Chicken Marinade',
        ingredients: [
          '1 large chicken breast, trimmed',
          '1 container frozen lemonade concentrate (thawed)',
          '6 tbsp pollo bouillon',
          '4 tbsp fresh garlic, chopped',
          '1 cup fresh mint leaves',
          '1/2 bunch fresh cilantro',
          '14 scallions, roughly chopped',
          '2 tbsp cumin',
          '1 tsp coriander',
          '1/2 tsp turmeric',
          '1/2 tsp curry',
          '1/2 tsp allspice',
          '1 tsp cinnamon',
          '1 tsp smoked paprika',
          '1 tsp cajun seasoning'
        ],
        ingredientsNote: '',
        instructions: [
          {
            description:
              'Add the lemonade concentrate, pollo bouillon, garlic, mint, cilantro, and scallions to a food processor. Blend on high until well combined. Add the remaining spices to the food processor; blend well again.',
            images: []
          },
          {
            description:
              'Place chicken in a gallon size resealable bag. Pour 1/4 cup of the marinade over chicken and seal the bag while pressing out excess air. Refrigerate for at least 4 hours or overnight.',
            images: []
          }
        ]
      },
      {
        partName: 'Berbere Tomatoes',
        ingredients: [
          '1 pint of cherry tomatoes',
          '3/4 tsp berbere spice',
          '1 tsp kosher salt',
          '4 tbsp olive-canola oil blend'
        ],
        ingredientsNote: '',
        instructions: [
          {
            description:
              'Preheat the oven to 425°F. In a medium bowl, toss the tomatoes, berbere spice, salt and olive-canola oil blend together. Pour the tomatoes onto a parchment-lined sheet pan and roast on the top oven rack for 6 minutes.',
            images: []
          }
        ]
      },
      {
        partName: 'Pickled Raisins',
        ingredients: [
          '¼ tbsp mustard seeds',
          '1/4 tbsp peppercorns',
          '1/2 tbsp apple pie spice',
          '1/4 tbsp ground ginger',
          '1/2 cup apple cider vinegar',
          '1/2 cup white wine vinegar',
          '1/8 cup water',
          '1/4 tbsp pickling spice',
          '1 tsp red pepper flakes',
          '1/4 tablespoon kosher salt',
          '3/4 cup golden raisins',
          '1/4 cup sugar',
          '2 sprigs fresh thyme'
        ],
        ingredientsNote: '',
        instructions: [
          {
            description:
              'Toast the mustard seeds. In a small saucepan, add everything except the raisins and thyme. Once the sugar has melted and it’s reached a simmer, add the raisins. Simmer on low for 8 mins. Add the thyme.',
            images: []
          },
          {
            description:
              'Remove from the heat and let cool for 30 minutes. Remove the thyme. Use straight away or store in the refrigerator and let it reach room temperatures before serving.',
            images: [gourmetLemonChicken2]
          }
        ]
      },
      {
        partName: 'Ras El Hanout Cauliflower',
        ingredients: [
          '1/2 cauliflower head',
          '1 heaping tsp ras el hanout',
          '1 tsp kosher salt ',
          '1 tbsp rice wine vinegar',
          '3 tbsp olive-canola oil blend'
        ],
        ingredientsNote: '',
        instructions: [
          {
            description:
              'Cut the cauliflower into equal bite-size florets. Mix up the rest of the ingredients in a large bowl. Add the florets, toss to coat. Spread out onto a parchment-lined sheet pan. Roast at 425°F for 8 minutes.',
            images: []
          }
        ]
      },
      {
        partName: 'Creamed Spinach',
        ingredients: [
          '½ medium-large eggplant, roasted off and cleaned up',
          '1 packet of frozen chopped spinach, thawed and squeezed',
          '1/2 cup of strong chicken stock made from bouillon',
          '1/4 cup heavy cream',
          '1/2 - 1 cup mixed cheese, finely grated ',
          'a few good grates of fresh nutmeg',
          'kosher salt ',
          'freshly ground black pepper'
        ],
        ingredientsNote: '',
        instructions: [
          {
            description:
              'In a food processor, blend ½ the roasted eggplant, spinach and heavy cream on high until well combined.',
            images: []
          },
          {
            description:
              'In a skillet over low heat, add the eggplant and spinach mixture, the chicken stock and freshly grated nutmeg and cook, until the sauce thickens. Stir in the mixed cheese and cook until reaching your desired thickness; loosen with water if necessary. Season with salt and pepper.',
            images: []
          }
        ]
      },
      {
        partName: 'Chicken Roulade',
        ingredients: [],
        ingredientsNote: '',
        instructions: [
          {
            description: 'Slice the roasted red pepper into thin strips.',
            images: [gourmetLemonChicken3]
          },
          {
            description:
              'Place the chicken breast between 2 sheets of heavy-duty plastic wrap, and using the flat side of a meat mallet, flatten to 1/4-inch thickness. Season with salt and pepper.',
            images: [gourmetLemonChicken4]
          },
          {
            description:
              'Take a large piece of plastic food wrap (about 18 inches long) and lay it down flat on your work surface. Place 1 chicken breast in the middle of the plastic wrap, seasoned side down. Spread teaspoon of marinade over the chicken. Place a tablespoon of the raisin mixture, leaving a 1/2-inch border around the edges. Lay 3 strips of roasted red pepper on top of the raisins, from end to end of the breast. Top with julienned spinach; season with salt and pepper.',
            images: []
          },
          {
            description:
              'Starting at the narrow end, begin rolling up the chicken breast, making sure all of the filling is tucked inside. Hold the ends and roll forward again and again, which will twist and tighten the ends of the plastic wrap. Tie of the ends. Repeat with remaining chicken breast. Wrap each chicken roulade with a large piece of foil and refrigerate for at least 2 hours.',
            images: [
              gourmetLemonChicken5,
              gourmetLemonChicken6,
              gourmetLemonChicken7
            ]
          },
          {
            description:
              'Bring a large pot of water to a boil. Once boiling, reduce to a simmer and drop the rolled-up chicken breasts into the water; steep for about 30 minutes.',
            images: []
          },
          {
            description:
              'Unwrap the foil, and use a thermometer to ensure the chicken registers at 165°F. Once cooked through, allow the chicken to rest for 10-20 minutes. Unwrap and slice into ½-¾ inch rounds.',
            images: [gourmetLemonChicken8]
          }
        ]
      }
    ],
    buildTheDish: [
      {
        description:
          'Arrange three chicken roulade slices on a large dinner plate. Spoon some of the creamed spinach to the side and top with roasted cauliflower and roasted tomatoes. Garnish with fresh microgreens. Garnish further with some of the sweet-and-spicy raisins.',
        images: [gourmetLemonChicken9]
      }
    ]
  }
};
