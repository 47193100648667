// @flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Styles
import './notice.scss';

// Types
type Props = {
  color: string,
  title?: string,
  className?: string,
  children: string
};

const Notice = ({ color, title, className, children, ...props }: Props) => {
  const classNames = classnames(
    'notice',
    { [`notice--${color}`]: color },
    className
  );

  return (
    <div className={classNames} {...props} role="alert">
      {title ? <p className="notice__title">{title}</p> : ''}
      <p>{children}</p>
    </div>
  );
};

export default Notice;
