export default {
  hasTransparentNav: false,
  hasRebateCallout: true,
  metadata: {
    title: '404 Not Found',
    description: 'Good vs. Gourmet',
    type: 'website'
  },
  title: '404 Page',
  className: 'not-found',
  hero: {
    image: '',
    title: '404'
  },
  content: 'Whoops, page not found!'
};
