// Assets
import heroImage from '../../assets/img/hero/chicken-and-waffles-hero.jpg';
import thumbnail from '../../assets/img/recipes/chicken-and-waffles-thumb.jpg';

export default {
  hasTransparentNav: false,
  hasRebateCallout: true,
  metadata: {
    title: 'Chicken and Waffles Recipe',
    description:
      'Two chicken&waffle recipes: Belgian-style waffle and polenta crusted chicken breast with spicy honey vs potato and leek waffle topped with chicken on jalapeño bacon gravy.',
    type: 'article'
  },
  title: 'Chicken and Waffles',
  hero: {
    image: heroImage,
    video: '',
    title: '',
    cta: '',
    link: ''
  },
  url: '/recipes/chicken-and-waffles',
  episode: 'chickenAndWaffles',
  chef: 'melissaAust',
  thumbnail,
  season: 1,
  tag: 'S1:E4',
  good: {
    stats: [
      {
        key: 'Servings',
        value: '2 to 4'
      }
    ],
    description:
      'Polenta Crusted Chicken with Spicy Honey Sauce on Belgian Waffles',
    note: '',
    recipeParts: [
      {
        partName: 'Polenta Crusted Chicken',
        ingredients: [
          '4 chicken breasts, pounded evenly thin',
          '¼ cup buttermilk',
          'kosher salt',
          'freshly ground black pepper',
          '1 cup polenta or cornmeal',
          '½ cup all-purpose flour',
          '½ cup freshly grated Parmesan',
          '1 tablespoon minced fresh basil',
          '1 tablespoon minced fresh parsley',
          'vegetable oil, for frying'
        ],
        ingredientsNote: '',
        instructions: [
          'In a small bowl, mix the chicken, buttermilk, and a pinch each of salt and pepper. Marinate for 10 minutes. In a separate shallow bowl, combine polenta, flour, Parmesan, basil, parsley, 1 teaspoon salt and ½ teaspoon pepper.',
          'Taking one chicken breast at a time, lay the chicken in the polenta, cover the top of the chicken with polenta mix and press the chicken into the mix on both sides. Set coated chicken aside on a plate.',
          'Heat 1-inch of oil in a high-sided skillet until it reaches 350°F on a thermometer. Add chicken and fry, turning once, until golden brown and cooked through, about 3 to 4 minutes per side.'
        ]
      },
      {
        partName: 'Spicy Honey Sauce',
        ingredients: ['½ cup honey', 'chili flake, to taste'],
        ingredientsNote: '',
        instructions: [
          'In a small saucepan over medium heat, heat up the honey and chili flake. Steep for a few minutes, but don’t let it come to a boil.'
        ]
      },
      {
        partName: 'Belgian Waffles',
        ingredients: [
          '2 cups all-purpose flour',
          '1 tablespoon sugar',
          '1 tablespoon baking powder',
          '¼ teaspoon salt',
          '1¾ cups ​buttermilk',
          '2 large eggs',
          '½ teaspoon vanilla extract',
          '½ cup butter melted'
        ],
        ingredientsNote: '',
        instructions: [
          'Preheat waffle maker per instructions. In a large bowl, combine the flour, sugar, baking soda and salt.',
          'In a second bowl, combine the buttermilk, eggs, vanilla and melted butter. Pour the buttermilk mixture into the flour mixture and combine until well incorporated. Spray waffle iron with non-stick spray or brush with melted butter before cooking waffles. Use approximately ½ to 1 cup batter for each waffle and cook according to your waffle maker’s instructions.'
        ]
      }
    ],
    buildTheDish: [
      'Place one waffle onto a large plate and top with two fried chicken breasts. Drizzle the spicy honey mixture, garnish with fresh parsley.'
    ]
  },
  gourmet: {
    stats: [
      {
        key: 'Servings',
        value: '2 to 4'
      }
    ],
    description:
      'Polenta Crusted Chicken with Bacon and Smoked Jalapeño Gravy on Potato and Leek Waffles',
    note: '',
    recipeParts: [
      {
        partName: 'Polenta Crusted Chicken',
        ingredients: [
          '4 chicken breasts, pounded evenly thin',
          '¼ cup buttermilk',
          'kosher salt and freshly ground black pepper',
          '1 cup polenta or cornmeal',
          '½ cup all-purpose flour',
          '½ cup grated Parmesan',
          '1 tablespoon minced fresh basil',
          '1 tablespoon minced fresh parsley',
          'vegetable oil, for frying'
        ],
        ingredientsNote: '',
        instructions: [
          'In a small bowl, mix the chicken, buttermilk, and a pinch each of salt and pepper. Marinate for 10 minutes. In a separate shallow bowl, combine polenta, flour, Parmesan, basil, parsley, 1 teaspoon salt and ½ teaspoon pepper.',
          'Taking one chicken breast at a time, lay the chicken in the polenta and then cover the top of the chicken with polenta mix and press the chicken into the mix on both sides. Set coated chicken aside on a plate.',
          'Heat 1-inch of oil in a high-sided skillet until it reaches 350°F on a thermometer. Add chicken and fry, turning once, until golden brown and cooked through, about 3 to 4 minutes per side.'
        ]
      },
      {
        partName: 'Bacon and Smoked Jalapeño Gravy',
        ingredients: [
          '1 pound bacon, cut into small dice',
          '½ yellow onion, cut into small dice',
          '½ cup all-purpose flour',
          '1 cup milk',
          '¼ cup minced fresh parsley',
          '1 jalapeño pepper, flame roasted, skin and seeds removed and minced',
          'salt and pepper to taste'
        ],
        ingredientsNote: '',
        instructions: [
          'In a straight sided skillet (a cast iron works best) over medium heat, cook the bacon until all the fat is rendered and the bacon is crispy. Transfer the bacon to a paper towel-lined plate.',
          'Add the onion to the skillet and sauté until golden brown and caramelized, about 7 to 10 minutes. Dust the flour over the onions and stir for 2 minutes to cook the flour. Add the milk and continue to stir until the mixture thickens.',
          'Stir in the parsley and jalapeño and add salt and pepper to taste. Keep warm until ready to serve.'
        ]
      },
      {
        partName: 'Potato and Leek Waffles',
        ingredients: [
          '4 medium russet potatoes, peeled and diced',
          '2 leeks, white and light green parts only, minced and rinsed well',
          '½ teaspoon red chili flakes',
          '1 tablespoon kosher salt, plus more for cooking potatoes',
          '1 teaspoon black pepper',
          '3 tablespoons butter',
          '1 cup heavy cream',
          '2 eggs, cracked into a bowl and whisked until broken up',
          '2 cups all-purpose flour'
        ],
        ingredientsNote: '',
        instructions: [
          'Place the potatoes in a pot of cold salted water. Bring to a boil and reduce heat to medium and cook until potatoes are tender all the way through. Strain potatoes and let them rest in the strainer to ensure they are as dry as possible.',
          'While potatoes are cooking, melt the butter in a sauté pan and add the leeks, chili flake, and salt and pepper then sauté leeks over medium heat until they are soft. Add the cream and simmer for 5 minutes.',
          'Preheat waffle maker per instructions. Place the potatoes, the leek mixture and eggs in the bowl of a stand mixer with the whisk attachment, and combine the ingredients on low speed for 20 seconds. Dust the flour over the potato mixture and switch to the dough hook attachment. Mix until the flour is incorporated, and the dough will form a very soft ball. The dough should be slightly firm. Add a bit more flour if needed. Cook according to your waffle maker’s instructions.'
        ]
      }
    ],
    buildTheDish: [
      'Cut one waffle into strips. Spoon ½ cup of the gravy onto the plate and swipe across the middle with a spoon. Stack and arrange waffle sticks on top, followed by two chicken fried breasts. Garnish with fresh micro basil or parsley.'
    ]
  }
};
