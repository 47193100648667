// Assets
import thumbnail from '../../assets/img/recipes/native-ragout-thumb.jpg';
import { goodNativeRagout1, gourmetNativeRagout1 } from '../recipes/images';

export default {
  hasTransparentNav: false,
  hasRebateCallout: true,
  metadata: {
    title: 'Native Ragout by Chef Sebastian Renner',
    description:
      'Loretta Barrett Oden, head chef at Thirty Nine Restaurant, walks us through two ways to create Native American ragout. Tune in for a special guest!',
    type: 'video.episode'
  },
  active: true,
  title: 'Native Ragout',
  hero: {
    image: '',
    video: '',
    title: '',
    cta: '',
    link: ''
  },
  episode: 'S2:E5',
  episodeLong: 'Season Two Episode Five',
  className: 'episode-detail',
  thumbnail,
  content:
    '“In order to get to know someone really well, share a meal with them.” Chef Loretta walks us through two ways to create Native American ragout with special guest OKC Mayor David Holt. Add Chef Loretta’s good and gourmet version of Native American ragout to your recipe collection.',
  excerpt:
    'Loretta Barrett Oden, head chef at Thirty Nine Restaurant, walks us through two ways to create Native American ragout. Tune in for a special guest!',
  videoIds: [
    {
      id: 'F6r6QbKsTBQ',
      title: 'Good Native Ragout',
      thumbnail: goodNativeRagout1
    },
    {
      id: 'D59Z79zX0FE',
      title: 'Gourmet Native Ragout ',
      thumbnail: gourmetNativeRagout1
    }
  ],
  playlistId: '',
  url: '/episodes/native-ragout',
  recipe: 'nativeRagout',
  chef: 'lorettaBarrettOden',
  season: 2
};
