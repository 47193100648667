// Vendors
import _ from 'lodash';

// Sources
import pages from './pages';
import episodes from './episodes';
import recipes from './recipes';

export const getPageData = page => {
  const data = {
    pages,
    episodes,
    recipes
  };

  return _.get(data, page, {});
};
