// @flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Styles
import './section.scss';

// Types
type Props = {
  color?: string,
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full',
  className?: string
};

const Section = ({ color = '', size = 'lg', className, ...props }: Props) => {
  const classNames = classnames(
    'section',
    {
      [`section--${size}`]: size,
      [`u--background-color-${color}`]: color
    },
    className
  );

  return <section className={classNames} {...props} />;
};

export default Section;
