// Assets
import image from '../../assets/img/chefs/tiffany-tisdale-braxton.jpg';

export default {
  name: 'Tiffany Tisdale-Braxton',
  firstName: 'Tiffany',
  role: 'Owner of Tisdale23 Catering and Event Planning',
  image,
  bio: "Chef Tiffany Tisdale-Braxton is a mother of three, wife and culinary influencer. As owner of Tisdale23 Catering and Event Planning, Tiffany has over nine years’ experience in the Oklahoma culinary scene. Her impressive resumé details high profile clientele and nationally recognized brands such as the Washington Redskins Organization, AT&T, the National Basketball Association, Lobeck Taylor Family Foundation, and the George Kaiser Family Foundation. Chef Tiffany is also the author of her newest e-book, “Work Mom's Guide to Surviving Weeknight Meals.” Her favorite types of food to cook also happen to be her favorite types to eat: Southern soul, Asian and Mediterranean.",
  cta: {
    text: 'Chef Tiffany shares her passion for cooking and how she got her culinary start.',
    videoId: 'SpgtNzoPz3g'
  },
  recipe: 'shrimpAndGrits',
  episode: 'shrimpAndGrits',
  season: 1
};
