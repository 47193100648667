// @flow

// Vendors
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import { bodyScroll } from '../../util/bodyScroll';

// Styles
import './video-modal.scss';

// Types
type Props = {
  handleClose: Function,
  show: boolean,
  title: string,
  videoId: string,
  playlistId?: string,
  className?: string
};

const VideoModal = ({
  handleClose,
  show,
  title,
  videoId,
  playlistId,
  className
}: Props) => {
  const modalRoot = document.getElementById('modal-root');
  const classNames = classnames(
    'video-modal',
    { [`video-modal--visible`]: show },
    className
  );
  let videoUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1`;
  if (playlistId) videoUrl += `&list=${playlistId}`;

  useEffect(() => {
    if (show) bodyScroll.disable();
    else bodyScroll.enable();
  }, [show]);

  return ReactDOM.createPortal(
    <>
      {show && (
        <Helmet>
          <body
            className={`${document.body.classList.value} page--modal-visible`}
          />
        </Helmet>
      )}
      <div className={classNames} onClick={handleClose}>
        <div
          className="video-modal__dialog"
          role="document"
          onClick={(e) => e.stopPropagation()}
        >
          <button
            type="button"
            className="video-modal__close"
            onClick={handleClose}
            aria-label="Close"
          />
          <div className="video-modal__content">
            <section className="video-modal__body">
              {show && (
                <iframe
                  src={videoUrl}
                  title={title}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                />
              )}
            </section>
          </div>
        </div>
      </div>
    </>,
    modalRoot
  );
};

export default VideoModal;
