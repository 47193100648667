// Assets
import heroImage from '../../assets/img/hero/ribeye-steak-hero.jpg';
import thumbnail from '../../assets/img/recipes/ribeye-steak-thumb.jpg';

export default {
  hasTransparentNav: false,
  hasRebateCallout: true,
  metadata: {
    title: 'Ribeye Steak Recipe',
    description:
      'Good ribeye steak with broccolini and roasted butternut squash vs. Gourmet reverse-seared ribeye steak with broccolini pesto and root vegetable ribbons.',
    type: 'article'
  },
  title: 'Ribeye Steak',
  hero: {
    image: heroImage,
    video: '',
    title: '',
    cta: '',
    link: ''
  },
  url: '/recipes/ribeye-steak',
  episode: 'ribeyeSteak',
  chef: 'justinThompson',
  thumbnail,
  season: 1,
  tag: 'S1:E1',
  good: {
    stats: [
      {
        key: 'Servings',
        value: '2 to 4'
      }
    ],
    description:
      'Pan Seared Ribeye Steak with Roasted Butternut Squash and Sautéed Broccolini',
    note: '',
    recipeParts: [
      {
        partName: 'Ribeye Steak',
        ingredients: [
          '1 ribeye steak, 14 to 16 oz (1 to 1½ inches thick)',
          'kosher salt',
          'freshly ground black pepper',
          '1 tablespoon avocado, grapeseed or peanut oil',
          '2 tablespoons butter',
          '3 to 4 sprigs thyme',
          '1 sprig rosemary',
          '4 to 5 garlic cloves'
        ],
        ingredientsNote: '',
        instructions: [
          'Begin by seasoning the ribeye with salt and pepper on all sides. Heat a cast iron skillet over medium high heat (a vent hood would be good at this point to keep from setting off the fire alarm). Add the oil to the pan. Place the steak in the screaming hot pan and sear on one side for about 1 to 2 minutes. Flip the steak and continue to sear for 1 to 2 minutes. Add the butter, thyme, rosemary and garlic. Remove the pan from the heat, and using a spoon, baste the steak with the butter for about 1 to 2 minutes. Remove the steak from the pan, pour the butter over the top of the steak and let it sit at room temperature for about 3 to 5 minutes. After the steak has rested, slice it into ½ inch thick slices.'
        ]
      },
      {
        partName: 'Roasted Butternut Squash',
        ingredients: [
          '1 butternut squash',
          '2 tablespoons butter, melted',
          '1 teaspoon salt',
          '1 teaspoon freshly ground black pepper'
        ],
        ingredientsNote: '',
        instructions: [
          'Preheat the oven to 450°F convection. Cut the top of the squash off. Slice the butternut squash in half lengthwise and scoop out the seeds with a spoon. Cut the remaining halves into 4 equal pieces lengthwise to create eight long wedges. Brush these with butter on all sides and season with salt and pepper. Place the squash onto a baker’s rack on top of a sheet pan and place in the oven to roast for 15 to 20 minutes or until the squash is cooked through (test with a fork for tenderness) and browned on all sides. Flip the wedges half-way through cooking.'
        ]
      },
      {
        partName: 'Sautéed Broccolini',
        ingredients: [
          '5 to 6 stalks broccolini, blanched in salted boiling water for 2 minutes and shocked in ice water immediately after',
          '1 tablespoon butter',
          '4 to 5 fresh sage leaves, cut chiffonade',
          '1 teaspoon red chili flakes',
          'grated zest of 1 lemon',
          '1 tablespoon finely minced shallots',
          'chopped chives, for garnish',
          'fresh sage leaves, for garnish'
        ],
        ingredientsNote: '',
        instructions: [
          'In a large sauté pan over medium heat, melt the butter until it begins to foam. Add the sage, chili flake, lemon zest and shallots. Sauté for just a minute and then add the broccolini.  Sauté to heat through. Season with salt and pepper to taste.'
        ]
      }
    ],
    buildTheDish: [
      'Arrange 2 to 3 wedges of the squash, 2 stalks of broccolini and a few slices of steak on a large dinner plate. Spoon some of the herb butter from the steak pan on top of the meat and add some of the garlic cloves as garnish. Garnish further with chopped chives and fresh sage.'
    ]
  },
  gourmet: {
    stats: [
      {
        key: 'Servings',
        value: '2 to 4'
      }
    ],
    description:
      'Reverse Sear Ribeye Steak with Roasted Root Vegetable Ribbons, Broccolini Pesto Sauce and Chopped Hazelnuts',
    note: 'To assemble this dish, you will first need to make all of the components. Start by roasting the steaks. While the steaks are in the oven, make the root vegetable ribbons and set aside. Make the pesto sauce and set aside. When the steak is ready to sear, heat the vegetable ribbons and simmer the pesto sauce.',
    recipeParts: [
      {
        partName: 'Reverse Sear Ribeye Steaks',
        ingredients: [
          '1 ribeye steak, 14 to 16 oz (1 to 1½ inches thick)',
          '2 tablespoons butter',
          '1 whole sprig rosemary',
          '3 to 4 sprigs fresh thyme',
          '4 to 5 whole garlic cloves',
          'kosher salt',
          'freshly ground black pepper',
          'chopped hazelnuts and micro basil or arugula, for garnish'
        ],
        ingredientsNote: '',
        instructions: [
          'Preheat the oven to 225°F. Season the steak with salt and pepper all over. Place the steak on a wire baking rack on top of a baking sheet. Place the steak in the oven for 45 min to 1 hour (depends on the thickness of the steak but check the internal temperature with a thermometer to read 115°F for medium rare).',
          'Remove the steak from the oven and let it rest on the countertop for 10 to 15 minutes. Heat a cast iron skillet over high heat and add butter, rosemary, thyme and garlic. Sear the steak on both sides for 1 minute each or until browned.',
          'Remove the steak from the pan and let rest for 3 to 5 minutes before slicing.'
        ]
      },
      {
        partName: 'Roasted Root Vegetable Ribbons',
        ingredients: [
          '1 butternut squash',
          '2 parsnips',
          '1 carrot',
          '1 sweet potato',
          '1 tablespoon butter',
          '1 tablespoon shallots, finely minced',
          '1 teaspoon fresh thyme, finely minced',
          '½ teaspoon red chili flakes',
          '¼ cup white wine',
          'grated zest of 1 lemon',
          'kosher salt',
          'freshly ground black pepper'
        ],
        ingredientsNote: '',
        instructions: [
          'Peel the vegetables, discarding the peel. Using the peeler, continue to peel long strips of each vegetable to create the ribbons. Place these to the side in a bowl and toss them to combine.',
          'In a large saucepan, over medium heat, melt the butter. Add the shallots and cook for 1 minute. Add the butter, minced shallot, lemon zest and chili flake. Before anything browns, add in the white wine. Simmer until the wine is reduced by half. Add the ribbons and toss to coat with the mixture. Cook, tossing often, until they are heated through. Season with salt and pepper to taste, then add the thyme. Be careful not to overcook the vegetables, they should still have some crunch.'
        ]
      },
      {
        partName: 'Broccolini Pesto Sauce',
        ingredients: [
          '3 to 4 stalks broccolini, blanched in salted boiling water for 2 minutes and shocked in ice water immediately after, then chopped',
          '1 cup spinach',
          '10 sage leaves',
          '¼ cup walnuts',
          '½ cup grated Parmesan',
          '½ teaspoon freshly ground black pepper, plus more to taste',
          '¼ cup olive oil',
          '½ cup heavy cream',
          'kosher salt, to taste'
        ],
        ingredientsNote: '',
        instructions: [
          'Blend broccolini, spinach, sage, walnuts, Parmesan and pepper in a food processor until well combined. Add the olive oil slowly until it is incorporated, and the mixture is fairly smooth. Season with salt and pepper to taste. Transfer the pesto to a small saucepan and heat over medium flame. Stir in heavy cream, bring to a gentle simmer, and cook, stirring constantly, until reduced to desired sauce consistency.'
        ]
      }
    ],
    buildTheDish: [
      'In a large pasta bowl or on a large dinner plate, spoon ¼ cup of the sauce onto the right side and swipe across the middle with a spoon. Place ¼ cup of the ribbons in a straight line across the sauce. Place 3 slices of the ribeye onto the ribbons and garnish with chopped hazelnuts and micro basil or arugula for spice.'
    ]
  }
};
