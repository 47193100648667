// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import { Button, Callout } from '../../components';

// Assets
import ovenRangeFooterImage from '../../assets/img/oven-range-footer.png';
import { ReactComponent as ONGLogo } from '../../assets/img/logos/oklahoma-natural-gas-logo.svg';

// Styles
import './footer.scss';

// Types
type Props = {
  className: string
};

const Footer = ({ className, ...props }: Props) => {
  const socialLinks = [
    {
      icon: 'twitter',
      url: 'https://twitter.com/oklahomanatural'
    },
    {
      icon: 'facebook-f',
      url: 'https://www.facebook.com/OklahomaNaturalGas/'
    },
    {
      icon: 'youtube',
      url: 'https://www.youtube.com/channel/UCk-pYj-qdUyAq2KfJQctVZQ'
    }
  ];
  const currentYear = () => {
    const year = new Date();
    return year.getFullYear();
  };

  const outboundLinkProps = {
    target: '_blank',
    rel: 'noopener noreferrer'
  };

  return (
    <footer
      id="site-footer"
      className={classnames('site-footer', className)}
      {...props}
    >
      <div className="grid">
        <div className="grid__col-xs-12 grid__col-sm-6 footer__ongc">
          <div className="site-footer__tagline">Brought to you by</div>
          <a
            className="site-footer__logo-link"
            href="https://www.oklahomanaturalgas.com"
            {...outboundLinkProps}
          >
            <ONGLogo
              className="site-footer__logo"
              aria-label="Oklahoma Natural Gas Logo"
            />
          </a>
          <div className="site-footer__icons">
            {socialLinks.map(({icon, url}) => (
              <a key={icon} href={url} {...outboundLinkProps}>
                <i className={`fab fa-${icon}`}></i>
              </a>
            ))}
          </div>
          <div className="footer-center">
            <Button id="ot-sdk-btn" class="ot-sdk-show-settings og-button-style-overrides">Cookie Settings</Button>
          </div>
        </div>
        <div className="grid__col-xs-12 grid__col-sm-6 footer__callout">
          <Callout
            text="Find Out How"
            link="https://www.oklahomanaturalgas.com/rebates"
            color="orange"
            image={ovenRangeFooterImage}
          >
            Get a $100 rebate on a new natural gas range
          </Callout>
        </div>
      </div>
      <div className="grid">
        <div className="footer__copyright footer-center">
          <p className="site-footer__copyright">
            &copy; {currentYear()} Oklahoma Natural Gas. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
