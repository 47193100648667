// Assets
import thumbnail from '../../assets/img/recipes/potato-gnocchi-thumb.jpg';
import { goodPotatoGnocchi1, gourmetPotatoGnocchi1 } from '../recipes/images';

export default {
  hasTransparentNav: false,
  hasRebateCallout: true,
  metadata: {
    title: 'Potato Gnocchi by Chef Kathryn Mathis',
    description:
      'Chef Kathryn Mathis, owner of Pizzeria Gusto in Oklahoma City, shows us how to make her delicious potato gnocchi, two ways.',
    type: 'video.episode'
  },
  active: true,
  title: 'Potato Gnocchi',
  hero: {
    image: '',
    video: '',
    title: '',
    cta: '',
    link: ''
  },
  episode: 'S2:E1',
  episodeLong: 'Season Two Episode One',
  className: 'episode-detail',
  thumbnail,
  content:
    'Even after 35 years of cooking, Chef Kathryn Mathis agrees that, “You don’t have to be creative to cook.” Give her potato gnocchi a try and learn how to take the dish from good to gourmet on a natural gas range.',
  excerpt:
    'Chef Kathryn Mathis, owner of Pizzeria Gusto in Oklahoma City, shows us how to make her delicious potato gnocchi, two ways.',
  videoIds: [
    {
      id: 'M1qNKzUhH5M',
      title: 'Good Potato Gnocchi',
      thumbnail: goodPotatoGnocchi1
    },
    {
      id: 'mry4lBZs6pg',
      title: 'Gourmet Potato Gnocchi ',
      thumbnail: gourmetPotatoGnocchi1
    }
  ],
  playlistId: '',
  url: '/episodes/potato-gnocchi',
  recipe: 'potatoGnocchi',
  chef: 'kathrynMathis',
  season: 2
};
