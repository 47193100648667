// @flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Styles
import './swatch.scss';

// Types
type Props = {
  name: string,
  slug: string,
  description: string,
  hex: string,
  className?: string
};

const Swatch = ({
  name,
  slug,
  description,
  hex,
  className,
  ...props
}: Props) => {
  const classNames = classnames(
    'swatch',
    { [`swatch--${slug}`]: slug },
    className
  );

  return (
    <div className={classNames} {...props}>
      <div className="swatch__color"></div>
      <p className="swatch__name">{name}</p>
      <p className="swatch__description">{description}</p>
      <p className="swatch__hex">{hex}</p>
    </div>
  );
};

export default Swatch;
