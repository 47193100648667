// @flow

// Vendors
import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

// Assets
import comingSoonImage from '../../assets/img/episodes/coming-soon.jpg';

// Styles
import './card.scss';

// Types
type Props = {
  url: string,
  as: 'a' | 'article',
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full',
  title: string,
  description?: string,
  image: string,
  className?: string
};

const Card = ({
  url,
  as,
  size = 'lg',
  title,
  description,
  image = comingSoonImage,
  className,
  tag,
  ...props
}: Props) => {
  const classNames = classnames(
    'card',
    {
      [`card--${size}`]: size,
      [`card__link`]: url
    },
    className
  );
  const tags = {
    a: 'a',
    article: 'article',
    Link
  };
  const T = tags[as] || as;

  return (
    <T className={classNames} to={url} {...props}>
      <div className="card__media-container">
        <img src={image} className="card__media" alt="" />
      </div>
      <section className="card__body">
        {tag && <p className="card__tag">{tag}</p>}
        {title && <h3 className="card__title">{title}</h3>}
        {description && <p className="card__description">{description}</p>}
      </section>
    </T>
  );
};

export default Card;
