// @flow

// Vendors
import React from 'react';

// Components
import { Icon } from '../../components';

const shareOptions = {
  facebook: ({ url, popupOptions }) =>
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
      'popUpWindow',
      popupOptions
    ),
  pinterest: ({ url, image, text, popupOptions }) =>
    window.open(
      `http://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(
        url
      )}&media=${encodeURIComponent(image)}&description=${encodeURIComponent(
        text
      )}`,
      'popUpWindow',
      popupOptions
    ),
  email: ({ url, title, text }) =>
    (window.location.href = `mailto:?subject=${encodeURIComponent(
      title
    )}&body=${encodeURIComponent(text)}%0D%0A%0D%0A${encodeURIComponent(url)}`)
};

const shareThis = ({ on, ...props }) => {
  const popupOptions =
    'height=436,width=626,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes';

  if (shareOptions[on]) shareOptions[on]({ ...props, popupOptions });
};

const getIcon = (on) => {
  if (!on) return null;

  const iconClasses = {
    facebook: 'fab fa-facebook-f',
    pinterest: 'fab fa-pinterest-p',
    email: 'fas fa-envelope'
  };

  if (iconClasses[on]) return <Icon className={iconClasses[on]} addCircle />;
};

const Share = (props) => (
  <span className="share__wrapper" onClick={() => shareThis(props)}>
    {getIcon(props.on)}
  </span>
);

export default Share;
