import justinThompson from './justinThompson';
import kurtFleischfresser from './kurtFleischfresser';
import tiffanyTisdaleBraxton from './tiffanyTisdaleBraxton';
import melissaAust from './melissaAust';
import kathrynMathis from './kathrynMathis';
import mirandaKaiser from './mirandaKaiser';
import jeffChanchaleune from './jeffChanchaleune';
import sebastianRenner from './sebastianRenner';
import lorettaBarrettOden from './lorettaBarrettOden';

const chefs = {
  justinThompson,
  tiffanyTisdaleBraxton,
  kurtFleischfresser,
  melissaAust,
  kathrynMathis,
  mirandaKaiser,
  jeffChanchaleune,
  sebastianRenner,
  lorettaBarrettOden
};

export const getChefs = () => Object.values(chefs);
export const getChef = (chef) => chefs[chef];
export const getSeason = (season) =>
  getChefs().filter((v) => v.season === season);

export default chefs;
