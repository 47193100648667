// Assets
import image from '../../assets/img/chefs/miranda-kaiser.jpg';

export default {
  name: 'Miranda Kaiser',
  firstName: 'Miranda',
  role: 'Former Head Chef and Owner of Laffa',
  image,
  bio: 'Miranda is an England native who spent 14 years in Israel and is now based in Tulsa. She was highlighted in PureWow as one of “20 extraordinary women running and cooking in restaurants with food that will bring you to your knees.”',
  recipe: 'lemonChicken',
  episode: 'lemonChicken',
  season: 2
};
