// @flow

// Vendors
import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

// Styles
import './video-card.scss';

// Icon
import Icon from '../Icon/Icon';

// Types
import VideoType from '../../types';
type Props = VideoType;

const VideoCard = ({
  showModal,
  size = 'lg',
  tag,
  title,
  image,
  className
}: Props) => {
  const classNames = classnames(
    'card video-card',
    {
      [`card--${size}`]: size
    },
    className
  );

  return (
    <Link to="#" onClick={showModal && showModal} className="video-trigger">
      <article className={classNames}>
        <div className="card__media-container">
          <Icon
            name="video-play-triangle-small"
            className="video-card__play-icon"
          />
          <img src={image} className="card__media" alt={title} />
        </div>
        <section className="card__body">
          {tag && <p className="card__tag">{tag}</p>}
          {title && <h3 className="card__title">{title}</h3>}
        </section>
      </article>
    </Link>
  );
};

export default VideoCard;
