// Assets
import heroImage from '../../assets/img/hero/pork-chops-hero.jpg';
import thumbnail from '../../assets/img/recipes/pork-chops-thumb.jpg';

export default {
  hasTransparentNav: false,
  hasRebateCallout: true,
  metadata: {
    title: 'Pork Chops by Chef Kurt Fleischfresser',
    description:
      'Chef Kurt Fleischfresser makes two versions of incredibly juicy pork chops. Whether you dig into his recipe for seared pork chops with country potatoes or his roasted double pork chops with garlic and pine nuts, his two methods are the best of the best.',
    type: 'video.episode'
  },
  active: true,
  title: 'Pork Chops',
  hero: {
    image: heroImage,
    video: '',
    title: '',
    cta: '',
    link: ''
  },
  episode: 'S1:E3',
  episodeLong: 'Season One Episode Three',
  className: 'episode-detail',
  thumbnail,
  content:
    'Put your pan-searing skills to practice with either of Chef Kurt Fleischfresser’s incredibly juicy pork chops. Whether you dig into his recipe for seared pork chops with country potatoes or his roasted double pork chops with garlic and pine nuts, his two methods are the best of the best.',
  excerpt:
    'OKC Chef Kurt Fleischfresser shows off his culinary chops by preparing this classic cuisine two ways.',
  videoIds: [ { id: '_dAK2uk4yfg' } ],
  playlistId: 'PL6bvPOjDXPzijS0wUPlVnGauAG99P2Ojn',
  url: '/episodes/pork-chops',
  recipe: 'porkChops',
  chef: 'kurtFleischfresser',
  season: 1
};
